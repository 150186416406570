import React from 'react'
import './Home.css'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

const OurServices = () => {
    return (
        <section className="position-relative  ourservises ">
            <img className="bg-img" src="./assets/img/backgroundimage.jpg" alt />
            <div className="bg-filter " />
            <div className="w-100 d-flex align-items-center justify-content-center flex-column">
                <h2 style={{ fontSize: '54px' }} className="display-6 pb-3 text-center pt-5 glow-text lh-1 mb-3 text-white fw-bold section-title g-text">
                What we offer
                </h2>
                <p className="text-center text-white">It means that MetaBlock is one of the providers of the easy and reliable IT solutions for any kind<br/> of companies. Our focus is on blockchain, online platforms, metaverse projects, gaming, and software development.<br/> No matter the type of requirement, MetaBlock offers today’s products and solutions that would definitely relevate the existence of any business.</p>
            </div>
            {/* <div class="w-100 d-flex align-items-center justify-content-center">
      <h1 class="display-6 pb-4 text-center pt-5 glow-text lh-1 mb-3 text-white fw-bold section-title">Our services</h1>
  </div> */}
            <div className=" d-flex justify-content-center flex-wrap py-5">

                <span className="ser-card bg-transparent" >

                    <div className="front" style={{ backgroundImage: ' linear-gradient(rgba( 0,0,0,0.2) , rgba(0,0,0,0.3)) , url(./images/image18.webp)' }}>
                        <h3 style={{ textShadow: '2px 2px 4px lightblue' }} className='font-famely-in '>Blockchain / Web3</h3>
                    </div>
                    <div className="back d-flex align-items-start flex-column justify-content-start py-4">
                        <div className="head1">
                            <h3 className="text-white fw-bold fs-3 grident-color-sub-title">Blockchain / Web3</h3>
                        </div>
                        <p>MetaBlock is a blockchain and Web3 software development company providing token solutions, DEX, DeFi, NFTs, wallets, and DAOs. Here we offer the business problematic and secure, scalable and customized solutions for being successful in Web3 environment
</p>
                        {/* <div>
                  <p>Consectetur adipisicing elit. Possimus, praesentium?</p>
                  <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p>
              </div> */}
                    </div>
                </span>
                <span className="ser-card bg-transparent" >

                    <div className="front" style={{ backgroundImage: ' linear-gradient(rgba( 0,0,0,0.2) , rgba(0,0,0,0.3)) , url(./images/image19.webp)' }}>
                        <h3 style={{ textShadow: '2px 2px 4px lightblue' }}>Web Development</h3>
                    </div>
                    <div className="back d-flex align-items-start flex-column justify-content-start py-4">
                        <div className="head1">
                            <h3 className="text-white fw-bold fs-3 grident-color-sub-title">Web Development</h3>
                        </div>
             <p>MetaBlock specifically covers web design and construction of efficient, safe and conveniently accessible presence on the internet for companies. We use the highest resolutions, present technologies, and innovations to develop websites that are responsive, scalable, and high in performance.  Our website development software solutions help your business be a strong online tool for success and advancement.</p>
                        {/* <div>
                  <p>Consectetur adipisicing elit. Possimus, praesentium?</p>
                  <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p>
              </div> */}
                    </div>


                </span>


                <span className="ser-card bg-transparent" >

                    <div className="front" style={{
                        backgroundImage: 'linear-gradient(rgba( 0,0,0,0.2) , rgba(0,0,0,0.3)) , url(./images/image20.webp)',
                    }}>
                        <h3 style={{ textShadow: '2px 2px 4px lightblue' }}>Software Development</h3>
                    </div>
                    <div className="back d-flex align-items-start flex-column justify-content-start py-4">
                        <div className="head1">
                            <h3 className="text-white fw-bold fs-3 grident-color-sub-title">Software Development</h3>
                        </div>
                        <p>MetaBlock provides software services to ease business tasks. Our erp software is used in running the day to day business, saas software is for the expansion of the businesses online while our LMS with live classes enhances learning experience. We also provide travel booking tools, restaurant tools, and MLM tools for bookings, restaurants, and marketing. Every program or service is created with purpose.</p>
                        {/* <div>
                  <p>Consectetur adipisicing elit. Possimus, praesentium?</p>
                  <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p>
              </div> */}
                    </div>

                </span>

            </div>
            <div className=" d-flex justify-content-center flex-wrap py-5">
                <span className="ser-card bg-transparent" >

                    <div className="front" style={{ backgroundImage: ' linear-gradient(rgba( 0,0,0,0.2) , rgba(0,0,0,0.3)) , url(./images/image21.webp)' }}>
                        <h3 style={{ textShadow: '2px 2px 4px lightblue' }}>Application Development</h3>
                    </div>
                    <div className="back d-flex align-items-start flex-column justify-content-start py-4">
                        <div className="head1">
                            <h3 className="text-white fw-bold fs-3 grident-color-sub-title" >Application Development</h3>
                        </div>
                        <p>MetaBlock itself offers an array of applications for quite diverse purposes: to book a cab, for communication, as a learning management platform, for instant messaging, dating, horoscope, and rentals. Every one of the applications is made to be intuitive and easy to use and navigate.</p>
                        {/* <div>
                  <p>Consectetur adipisicing elit. Possimus, praesentium?</p>
                  <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p>
              </div> */}
                    </div>

                </span>
                <span className="ser-card bg-transparent" >

                    <div className="front" style={{ backgroundImage: ' linear-gradient(rgba( 0,0,0,0.2) , rgba(0,0,0,0.3)) , url(./images/image22.webp)' }}>
                        <h3 style={{ textShadow: '2px 2px 4px lightblue' }}>Gaming Development</h3>
                    </div>
                    <div className="back d-flex align-items-start flex-column justify-content-start py-4">
                        <div className="head1">
                            <h3 className="text-white fw-bold fs-3 grident-color-sub-title">Gaming Development</h3>
                        </div>
                        <p>MetaBlock is focused on the gaming industry and delivers a number of active games, such as Skilled Games, Rummy, Teen Patti, CallBreak, Fantasy Cricket and Poker. We also offer tournament services, which involves building interesting events for players to engage in a battle. Every game development follows the best design with levels and excellent graphics to ensure that users are engaged.</p>
                        {/* <div>
                  <p>Consectetur adipisicing elit. Possimus, praesentium?</p>
                  <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p>
              </div> */}
                    </div>

                </span>
                <span className="ser-card bg-transparent" >

                    <div className="front" style={{ backgroundImage: ' linear-gradient(rgba( 0,0,0,0.2) , rgba(0,0,0,0.3)) , url(./images/image23.webp)' }}>
                        <h3 style={{ textShadow: '2px 2px 4px lightblue' }}>Metaverse </h3>
                    </div>
                    <div className="back d-flex align-items-start flex-column justify-content-start py-4">
                        <div className="head1">
                            <h3 className="text-white fw-bold fs-3 grident-color-sub-title">Metaverse </h3>
                        </div>
                        <p>MetaBlock provides Metaverse development solutions in Metaverse games, Metaverse avatars, and Metaverse lands. We also create Metaverse event platforms for engaging high-quality interactive virtual events. All the solutions we create are to offer a unique, challenging and captivating digital environment for the users.</p>

                    </div>

                </span>

            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Link to="/contact-us">  <div className="HddrBtns mt-4">
                    <button
                        data-toggle="modal"
                        data-target="#popup_form_modal"
                        className="btn BtnPrimry TlkBtn"
                        target="_blank"
                    >
                        <span className="img-fluid lazyload mr-2">
                            Visit Now
                        </span>
                        <span>
                            Visit Now
                        </span>
                    </button>
                </div></Link>
            </div>
        </section>
    )
}

export default OurServices
