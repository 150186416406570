import React, { useState, useRef, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Sidebar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlogDropdownOpen, setIsBlogDropdownOpen] = useState(false);
  const menuRef = useRef();
  const navigate = useNavigate();
  const blogMenuRef = useRef();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleBlogToggle = () => {
    setIsBlogDropdownOpen(!isBlogDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (blogMenuRef.current && !blogMenuRef.current.contains(event.target)) {
      setIsBlogDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, isBlogDropdownOpen]);

  const handleLogout = async () => {
    try {
      // Make the API request to log out
      await axios.post(
        "https://maincompanybackend.metablocktechnologies.org//api/v1/authlogout/logout"
      );

      // Redirect to login page or home page
      navigate("/admin");
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle error if needed
    }
  };

  return (
    <nav className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMobileMenuOpen ? "hidden" : "block"} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <svg
                className={`${isMobileMenuOpen ? "block" : "hidden"} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://metablocktechnologies.in/images/metablock-logo-main.jpg"
                alt="Your Company"
              />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <Link
                  to="Category"
                  className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Category
                </Link>
                <Link
                  to="galleryadmin"
                  className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Gallery
                </Link>
                <Link
                  to="teamadmin"
                  className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Our Team
                </Link>
                <Link
                  to="videoadmin"
                  className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Video
                </Link>

                <div className="relative" ref={blogMenuRef}>
                  <button
                    onClick={handleBlogToggle}
                    className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white focus:outline-none"
                  >
                    Blog
                  </button>
                  {isBlogDropdownOpen && (
                    <div className="absolute left-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                      <Link
                        to="Blog_categories"
                        className="block px-4 py-2 text-sm text-black hover:bg-gray-100"
                      >
                        Blog Categories
                      </Link>
                      <Link
                        to="Blog_add"
                        className="block px-4 py-2 text-sm  hover:bg-gray-100 text-black"
                      >
                        Blog Posts
                      </Link>

                      <Link
                        to="Blog_main_add"
                        className="block px-4 py-2 text-sm  hover:bg-gray-100 text-black"
                      >
                        Main Blog
                      </Link>
                      <Link
                        to="Blog_topic"
                        className="block px-4 py-2 text-sm  hover:bg-gray-100 text-black"
                      >
                        More Topic Category
                      </Link>
                      <Link
                        to="Blog_topic_add"
                        className="block px-4 py-2 text-sm  hover:bg-gray-100 text-black"
                      >
                        More Topic add
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Profile dropdown */}
            <div className="relative ml-3 text-black" ref={menuRef}>
              <div>
                <button
                  type="button"
                  className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="user-menu-button"
                  aria-expanded={isOpen}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <CgProfile className="h-8 w-8 rounded-full" />
                </button>
              </div>
              {isOpen && (
                <div
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-black"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-0"
                  >
                    Your Profile
                  </Link>
                  <Link
                    to="/settings"
                    className="block px-4 py-2 text-sm  text-black"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-1"
                  >
                    Settings
                  </Link>
                  <Link
                    to="#"
                    onClick={handleLogout}
                    className="block px-4 py-2 text-sm text-black"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-2"
                  >
                    Logout
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Mobile menu, show/hide based on menu state. */}
      <div
        className={`sm:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}
        id="mobile-menu"
      >
        <div className="space-y-1 px-2 pb-3 pt-2">
          <Link
            to="Category"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Category
          </Link>
          <Link
            to="galleryadmin"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Gallery
          </Link>
          <Link
            to="teamadmin"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Our Team
          </Link>
          <Link
            to="videoadmin"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Video
          </Link>
          <div className="relative">
            <button
              onClick={handleBlogToggle}
              className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white w-full text-left"
            >
              Blog
            </button>
            {isBlogDropdownOpen && (
              <div className="space-y-1">
                <Link
                  to="Blog_categories"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Blog Categories
                </Link>
                <Link
                  to="Blog_add"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Blog Posts
                </Link>
                <Link
                  to="Blog_topic"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Blog Topic Category
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;

// import React, { useState, useRef, useEffect } from "react";
// import { Link, Outlet } from 'react-router-dom';
// import { CgProfile } from "react-icons/cg";
// import { IoMdArrowDropdown } from "react-icons/io"; // Add this for dropdown arrow icon

// const Sidebar = () => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isBlogDropdownOpen, setIsBlogDropdownOpen] = useState(false);
//   const menuRef = useRef();
//   const blogDropdownRef = useRef();

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleBlogDropdownToggle = () => {
//     setIsBlogDropdownOpen(!isBlogDropdownOpen);
//   };

//   const handleClickOutside = (event) => {
//     if (menuRef.current && !menuRef.current.contains(event.target)) {
//       setIsOpen(false);
//     }
//     if (blogDropdownRef.current && !blogDropdownRef.current.contains(event.target)) {
//       setIsBlogDropdownOpen(false);
//     }
//   };

//   useEffect(() => {
//     if (isOpen || isBlogDropdownOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen, isBlogDropdownOpen]);

//   return (
//     <nav className="bg-gray-800">
//       <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
//         <div className="relative flex h-16 items-center justify-between">
//           <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
//             {/* Mobile menu button */}
//             <button
//               type="button"
//               className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
//               aria-controls="mobile-menu"
//               aria-expanded={isMobileMenuOpen}
//               onClick={toggleMobileMenu}
//             >
//               <span className="absolute -inset-0.5" />
//               <span className="sr-only">Open main menu</span>
//               <svg
//                 className={`${isMobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth="1.5"
//                 stroke="currentColor"
//                 aria-hidden="true"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
//                 />
//               </svg>
//               <svg
//                 className={`${isMobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth="1.5"
//                 stroke="currentColor"
//                 aria-hidden="true"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//           <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
//             <div className="flex flex-shrink-0 items-center">
//               <img
//                 className="h-8 w-auto"
//                 src="https://metablocktechnologies.in/images/metablock-logo-main.jpg"
//                 alt="Your Company"
//               />
//             </div>
//             <div className="hidden sm:ml-6 sm:block">
//               <div className="flex space-x-4">
//                 {/* <Link
//                   className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white"
//                   aria-current="page"
//                 >
//                   Dashboard
//                 </Link> */}
//                 <Link
//                   to="categoryadmin"
//                   className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//                 >
//                   Category
//                 </Link>
//                 <Link
//                   to="galleryadmin"
//                   className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//                 >
//                   Gallery
//                 </Link>
//                 <Link
//                   to="teamadmin"
//                   className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//                 >
//                   Our Team
//                 </Link>
//                 <Link
//                   to="videoadmin"
//                   className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//                 >
//                   Video
//                 </Link>
//                 <div className="relative" ref={blogDropdownRef}>
//                   <button
//                     type="button"
//                     className="flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//                     onClick={handleBlogDropdownToggle}
//                   >
//                     Blog
//                     <IoMdArrowDropdown className="ml-2 h-4 w-4" />
//                   </button>
//                   {isBlogDropdownOpen && (
//                     <div
//                       className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black"
//                       role="menu"
//                       aria-orientation="vertical"
//                       aria-labelledby="user-menu-button"
//                       tabIndex={-1}
//                     >
//                       <Link
//                         to="/blogcategories"
//                         className="block px-4 py-2 text-sm text-black"
//                         role="menuitem"
//                         tabIndex={-1}
//                       >
//                         Blog Categories
//                       </Link>
//                       <Link
//                         to=""
//                         className="block px-4 py-2 text-sm text-black"
//                         role="menuitem"
//                         tabIndex={-1}
//                       >
//                         Add Blog
//                       </Link>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
//             {/* Profile dropdown */}
//             <div className="relative ml-3 text-black" ref={menuRef}>
//               <div>
//                 <button
//                   type="button"
//                   className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                   id="user-menu-button"
//                   aria-expanded={isOpen}
//                   aria-haspopup="true"
//                   onClick={handleToggle}
//                 >
//                   <span className="absolute -inset-1.5" />
//                   <span className="sr-only">Open user menu</span>
//                   {/* <img
//                     className="h-8 w-8 rounded-full"
//                     src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fpngtree.com%2Fso%2Fprofile-icon&psig=AOvVaw2OGEFFKrmSYcsvVw89x629&ust=1721907358753000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCNCHy_TKv4cDFQAAAAAdAAAAABAE"
//                     alt="User avatar"
//                   /> */}
//                   <CgProfile className="h-8 w-8 rounded-full" />
//                 </button>
//               </div>
//               {isOpen && (
//                 <div
//                   className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black"
//                   role="menu"
//                   aria-orientation="vertical"
//                   aria-labelledby="user-menu-button"
//                   tabIndex={-1}
//                 >
//                   <Link
//                     to="/profile"
//                     className="block px-4 py-2 text-sm text-black"
//                     role="menuitem"
//                     tabIndex={-1}
//                     id="user-menu-item-0"
//                   >
//                     Your Profile
//                   </Link>
//                   <Link
//                     to="/settings"
//                     className="block px-4 py-2 text-sm text-black"
//                     role="menuitem"
//                     tabIndex={-1}
//                     id="user-menu-item-1"
//                   >
//                     Settings
//                   </Link>
//                   <Link
//                     to="/logout"
//                     className="block px-4 py-2 text-sm text-black"
//                     role="menuitem"
//                     tabIndex={-1}
//                     id="user-menu-item-2"
//                   >
//                     Sign out
//                   </Link>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Mobile menu, show/hide based on menu state. */}
//       <div className={`sm:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
//         <div className="space-y-1 px-2 pb-3 pt-2">
//           {/* <Link
//             to="/"
//             className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
//             aria-current="page"
//           >
//             Dashboard
//           </Link> */}
//           <Link
//             to="categoryadmin"
//             className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//           >
//             Category
//           </Link>
//           <Link
//             to="galleryadmin"
//             className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//           >
//             Gallery
//           </Link>
//           <Link
//             to="teamadmin"
//             className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//           >
//             Our Team
//           </Link>
//           <Link
//             to="videoadmin"
//             className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//           >
//             Video
//           </Link>
//           <div className="relative">
//             <button
//               type="button"
//               className="flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
//               onClick={handleBlogDropdownToggle}
//             >
//               Blog
//               <IoMdArrowDropdown className="ml-2 h-4 w-4" />
//             </button>
//             {isBlogDropdownOpen && (
//               <div
//                 className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black"
//                 role="menu"
//                 aria-orientation="vertical"
//                 aria-labelledby="user-menu-button"
//                 tabIndex={-1}
//               >
//                 <Link
//                   to="Blog_categories"
//                   className="block px-4 py-2 text-sm text-black"
//                   role="menuitem"
//                   tabIndex={-1}
//                 >
//                   Blog Categories
//                 </Link>
//                 <Link
//                   to="blogadd"
//                   className="block px-4 py-2 text-sm text-black"
//                   role="menuitem"
//                   tabIndex={-1}
//                 >
//                   Add Blog
//                 </Link>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Sidebar;
