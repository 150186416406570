import React from 'react'

const ChallengesInYourBusiness = () => {
    return (
        <>
            <main id="smart_contract_dev_main">
                <section className="smart_contract_dev_process">
                    <div className="container">
                        <h2 className="ChallengesInYourBusiness-main-head grident-color-sub-title">Challenges In Your Business</h2>
                        <p>MetaBlock, we try to find out specific requirements from each client and make sure our services do enhance the site’s efficiency, sustainability, and capacity; We also provide service after a client has bought our product. Still, it would help if you did not worry about such challenges – we are eager to help to overcome them with our Blockchain App Development Services.
</p>
                        <div className="process_box">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5 col-12 order-2 order-lg-1">
                                    <div className="aos-init aos-animate" data-aos="fade-right" data-aos-delay={500} >
                                        <div className="num_sec">01</div>
                                        <h3 className="sub_title grident-color-sub-title">Slow website</h3>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 order-1 order-lg-2">
                                    <div className="img_sec aos-init aos-animate" data-aos="fade-up" data-aos-delay={500}>
                                        <img width={'80px'} src="./images/number-1.webp" alt="Analyzing the Requirement" title="Analyzing the Requirement" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12 order-3 order-lg-3">
                                    <div className="aos-init aos-animate" data-aos="fade-left" data-aos-delay={500}>
                                        <p className="sub_contents">In addition, slow websites will only push people away and hinder the site’s ability to grow.” MetaBlock increases speed to retain clients, and also improve efficiency
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process_box">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5 col-12 order-3 order-lg-1">
                                    <div className="aos-init aos-animate" data-aos="fade-right" data-aos-delay={500}>
                                        <p className="sub_contents even_sub_cont">Slow websites frustrate users, leading to lost business and fewer conversions. MetaBlock ensures a faster website to keep customers happy and loyal.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 order-1 order-lg-2">
                                    <div className="img_sec aos-init aos-animate" data-aos="fade-up" data-aos-delay={500}>
                                        <img width={'80px'} src="./images/number-2.webp" alt="Analyzing the Requirement" title="Analyzing the Requirement" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12 order-2 order-lg-3">
                                    <div className="aos-init aos-animate" data-aos="fade-left" data-aos-delay={500}>
                                        <div className="num_sec even_num_sec">02</div>
                                        <h3 className="sub_title grident-color-sub-title even_sub_tit">BUGGY SOFTWARE</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process_box">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5 col-12 order-2 order-lg-1">
                                    <div className="aos-init aos-animate" data-aos="fade-right" data-aos-delay={500}>
                                        <div className="num_sec">03</div>
                                        <h3 className="sub_title grident-color-sub-title">ZERO AFTER  SALE SUPPORT </h3>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 order-1 order-lg-2">
                                    <div className="img_sec aos-init aos-animate" data-aos="fade-up" data-aos-delay={500}>
                                        <img width={'80px'} src="./images/number-3.webp" alt="Analyzing the Requirement" title="Analyzing the Requirement" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12 order-3 order-lg-3">
                                    <div className="aos-init aos-animate" data-aos="fade-left" data-aos-delay={500}>
                                        <p className="sub_contents">Sometimes customers do not receive support after being sold a product and they become helpless. MetaBlock guarantees reliable service and client support for maximum customer satisfaction after the purchase

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process_box">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5 col-12 order-3 order-lg-1">
                                    <div className="aos-init aos-animate" data-aos="fade-right" data-aos-delay={500}>
                                        <p className="sub_contents even_sub_cont">
                                        Complex Web applications frustrate users and lead to negative effects on loyalty as well as brand image. MetaBlock is designed for the convenient and satisfying navigation interface of websites.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 order-2 order-lg-2">
                                    <div className="img_sec aos-init aos-animate" data-aos="fade-up" data-aos-delay={500}>
                                        <img width={'80px'} src="./images/number-4.webp" alt="Analyzing the Requirement" title="Analyzing the Requirement" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12 order-2 order-lg-3">
                                    <div className="aos-init aos-animate" data-aos="fade-left" data-aos-delay={500}>
                                        <div className="num_sec even_num_sec">04</div>
                                        <h3 className="sub_title grident-color-sub-title even_sub_tit">POOR UI/UX</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process_box">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5 col-12 order-2 order-lg-1">
                                    <div className="aos-init aos-animate" data-aos="fade-right" data-aos-delay={500}>
                                        <div className="num_sec">05</div>
                                        <div className="sub_title grident-color-sub-title">Speed and loading issues</div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 order-1 order-lg-2">
                                    <div className="img_sec aos-init aos-animate" data-aos="fade-up" data-aos-delay={500}>
                                        <img width={'80px'} src="./images/number-5.webp" alt="Analyzing the Requirement" title="Analyzing the Requirement" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12 order-3 order-lg-3">
                                    <div className="aos-init aos-animate" data-aos="fade-left" data-aos-delay={500}>
                                        <div className="sub_contents">
                                        Slow website speed and cases where a certain part of a website loads can be irritating to the users of the site. That is why they cannot find what they are looking for and end up dissatisfied. This is bad for your website as it impacts the credibility of your site and users are likely to leave. For loading problems, MetaBlock has solutions to prevent slow and/or partial page load so there is a smoother experience for the user.

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process_box">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5 col-12 order-3 order-lg-1">
                                    <div className="aos-init aos-animate" data-aos="fade-right" data-aos-delay={500}>
                                        <div className="sub_contents even_sub_cont">
                                        MetaBlock, we incorporate modern solutions that will make your site as elastic as possible, that way will be able to expand it just as your company is expanding. As a result, you guarantee continuous performance and sustainable development as your enterprise grows.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 order-1 order-lg-2">
                                    <div className="img_sec aos-init aos-animate" data-aos="fade-up" data-aos-delay={500}>
                                        <img width={'80px'} src="./images/number-6.webp" alt="Analyzing the Requirement" title="Analyzing the Requirement" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12 order-2 order-lg-3">
                                    <div className="aos-init aos-animate" data-aos="fade-left" data-aos-delay={500}>
                                        <div className="num_sec even_num_sec">06</div>
                                        <div className="sub_title grident-color-sub-title even_sub_tit">Growth & scalability Issue</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default ChallengesInYourBusiness
