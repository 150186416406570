import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import { useNavigate } from "react-router-dom";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import SeoHelmet from "../../../Helmet";

import FAQSection2 from "../../FAQSection2";
import TechnologyStack from "../../../components/TechnologyStack";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import KeyFeatures from "../../KeyFeatures";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import WhyChooseUs2 from "../../WhyChooseUs2";
import OurProcedureNew from "../../OurProcedureNew";

// import '../../main.css'

const ERPSoftware = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle=" Custom ERP Software Development Company for Trim Business Operation
"
          description="Transform your business operations with a personalized Best Custom ERP software development company. Our custom solutions optimize processes, boost 
efficiency, and deliver real-time insights to faster growth and ensure lasting success.



"
          keywords="Custom ERP Software Development Company"
        />

        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      {/* Blockchain Finance Management */} Custom ERP Software
                      Development: Driving Digital Transformation for
                      Enterprises
                    </h1>
                    <p className="heading-tagline">
                      Developing Enterprise Resource Planning, or custom ERP
                      software development, entails creating, designing, and
                      implementing software systems that facilitate companies'
                      management and integration of different important parts of
                      their operations. ERP systems are widely used to automate
                      and harmonize departments across finance, human resources,
                      supply chain, manufacturing, and customer relationships
                      onto a single platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialists in ERP Software Development Solutions
                </h2>
                <p style={{ fontSize: "20px" }}>
                  An ERP specialist is a professional who designs, customizes, &
                  implements an ERP system in various business process areas
                  like finance, human resources, manufacturing, & supply chain
                  management. Their knowledge in the areas of an ERP platform,
                  programming language, & business processes makes it possible
                  for them to customize the solutions that cater best to the
                  specific needs of organizations.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img
                  src="./images/devanshusoftware-1.jfif.webp"
                  alt="devanshusoftware"
                />
              </div>
            </div>
          </section>

          <SwiperSlider />
          {/* <KeyFeature /> */}
          <KeyFeatures
            heading=" Key Feature of Erp Software Development "
            description=" Key Features of Our ERP Software Development Services: ERP Solutions for Maximum Impact"
            filterKey="erp" // Pass 'software' as filterKey
          />
          <TechnologyStack />

          <Industries
            category="erp"
            heading="Industries That Use Our Software Solutions" // Dynamic heading for software
          />

          <AdminControlFeatures
            category="erp"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />

          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
            <OurProjects
              projectData={projectData}
              heading="Browse our impactful portfolio showcase"
              description=" Our services encompasses web development,app development,game development,software development and blockchain development."
              filterKey="erp" // Display only software projects
            />
          </section>
          {/* <section>
            <WhyChooseUs2 category="erp"/>
          </section> */}
          <section>
            <SimilarFile />
          </section>
          <FAQSection2 category="erp" />
          <OurProcedureNew category="erp" />
        </div>
      </div>
    </>
  );
};

export default ERPSoftware;
