import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";
import WareHouseSwiperSlider from "./SwiperSlider/WareHouseSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

// import '../../main.css'

const WarehouseManagmentSoftware = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    {/* Blockchain Finance Management */}Warehouse Managment
                    Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Warehouse Management Software optimizes inventory control
                    and distribution operations, streamlining processes from
                    receiving to shipping. By integrating real-time data
                    analytics and automation, it enhances visibility, accuracy,
                    and efficiency, empowering businesses to meet customer
                    demands while minimizing costs and errors.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in warehouse management software, our expertise
                lies in providing comprehensive solutions tailored to the unique
                needs of businesses involved in storage, logistics, and
                distribution. Our software is designed to optimize inventory
                management, streamline workflows, and enhance overall efficiency
                within warehouse operations. From inventory tracking and order
                fulfillment to automated replenishment and reporting, our
                solutions empower businesses to maximize their warehouse space,
                reduce operational costs, and improve customer satisfaction.
                With our specialized knowledge and dedication to innovation, we
                strive to equip organizations with the tools they need to
                succeed in the dynamic world of warehouse management.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/devanshusoftware-3.jfif.webp" alt="devanshusoftware" />
            </div>
          </div>
        </section>

        <WareHouseSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
          <CardSlider/>
        </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
          <Phonecardslider />
        </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default WarehouseManagmentSoftware;
