import React from "react";
import "./Static.css";
import SimilarFile from "../SimilarFile";
import { AiOutlineGlobal } from "react-icons/ai";
import { RiCustomerServiceFill } from "react-icons/ri";
import { SiSpringsecurity } from "react-icons/si";
import { FcManager } from "react-icons/fc";
import SeoHelmet from "../../Helmet";
import FAQSection2 from "../FAQSection2";

const Static = () => {
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="MetaBlock Technologies: Static Website Development Services in India
"
          description="MetaBlock Technologies: The best web development company in India and specializes in static, dynamic, and e-commerce websites.

"
keywords="Static Website Development Services"

        />
        &lt;&gt;
        <header className="ondemad_head">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div class="text-left header-content col-lg-12 mt-9">
                <h1
                  className="mb-5 mt-40 Static-heading"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Static Website Development Services
                </h1>
              </div>
              <div className="col-lg-12 my-auto mb-1">
                <div className="header-content mx-auto E-commerce-header-paragraph">
                  {/*  <h1 class="mb-5 mobile" style="text-align: left;">On-Demand App Development Company </h1> */}
                  <p className="text-left heading-tagline">
                    Boost your online presence with our Static Website
                    Development services. We create beautiful and easy-to-use{" "}
                    <br />
                    static websites that showcase your brand and provide a
                    smooth experience for your visitors.
                  </p>
                  <br />
                  <ul className="banr-listing">
                    <li>
                      <i className="fa fa-check-circle" /> Quick Loading Speed
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Cost-Effective
                    </li>
                    <li>
                      <i className="fa fa-check-circle" />
                      High Security
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Easy Maintenance
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Reliability and
                      Stability
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="mid_section ondemad_feature embraced-features">
          <div className="container">
            <div className="section-heading text-center align-center d-flex flex-column grident-color-sub-title">
              <h2 style={{ fontSize: "45px" }} className="features_title ">
                Built-in Features
              </h2>
              <p className="text-center">
                We create a static website design with many features, such as
                tracking tools, payment options, and more, as listed below.
              </p>
              <div className="why_round"> </div>
            </div>
            <div className="row">
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box1 hgt_400">
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./images/map-setting.webp"
                          alt="GPS Services"
                          title="GPS Services"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <h3>Location Services</h3>
                  <p className="text-center">
                    We add static maps to your website so that users and service
                    providers can see location information. This helps providers
                    understand where users are and lets users check the location
                    of goods or services.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box2 hgt_400">
                  <h3>Online Payments</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./images/digital-wallet.webp"
                          alt="In - App Payments"
                          title="In - App Payments"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    Our static sites come with secure and dependable payment
                    options, so your customers can easily and securely complete
                    their purchases directly at the site. The entire process
                    ensures that the customer feels smooth and hassle-free
                    without exposing his sensitive information to the hacker.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box3 hgt_400">
                  <h3>Cloud Integration </h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./SGimage/integrated-system.webp"
                          alt="Cloud Integration"
                          title="Cloud Integration"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    In our cloud development of a static web, we have a
                    completely dedicated cloud space for analytics and data
                    storage. So this makes the information concerned with
                    website usage more readable, efficient, and very accessible,
                    thus easier to track and understanding user behavior.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box4 hgt_400">
                  <h3>User Engagement Analytics</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./SGimage/analytics.webp"
                          alt="Instant Push Notification"
                          title="Instant Push Notification"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    Our static website development incorporates powerful
                    analytics tools that track and study how users interact with
                    your site. This provides information to improve your online
                    presence and user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mid_section why-business grey-bg why-should-develop">
          <div className="container">
            <div className="section-heading text-center d-flex justify-center align-center flex-column grident-color-sub-title">
              <h2 style={{ fontSize: "35px" }} className="why_title">
                Why Should You develop an Static Website for Your Business?
              </h2>
              <div className="why_round"> </div>
            </div>
            <div className="row mar-top-30">
              <div className="col-md-12 col-sm-12">
                <p className="why_para text-center">
                  In today's digital world, dynamic and static websites play a
                  significant role in enhancing your online presence. Since
                  users are seeking easier access to information, well-crafted
                  static websites can be essential for businesses looking to
                  start or grow their online reach and lay the foundations for
                  long-term success.
                </p>
                <div className="row mar-top-30">
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        {/* <img
                        src="./SGimage/advertising.webp"
                        alt="Global Reach"
                        title="Global Reach"
                        style={{ objectPosition: "-7px -18px" }}
                      /> */}
                        <AiOutlineGlobal />
                      </abbr>
                      <h3>Online Presence</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <RiCustomerServiceFill />
                      </abbr>
                      <h3>User Convenience</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <SiSpringsecurity />
                      </abbr>
                      <h3>Reliability & Simplicity</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <FcManager />
                      </abbr>
                      <h3>Strategic Advancements</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-heading mid_section-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 grident-color-sub-title">
                <h2
                  style={{ color: "#D166AF ", fontSize: "40px" }}
                  className="text-center Technology-Stack"
                >
                  Technology Stack We Use
                </h2>
              </div>
            </div>
            <div className="row tech p-5 pt-0 mt-5 mb-5">
              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/physics.webp"
                  className="w-100 h-40"
                  alt="React"
                />
                <h5>React</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5>Python</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mysql.webp"
                  alt="MySQL"
                  className="w-100 h-40"
                />
                <h5>MySQL</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/java-script.webp"
                  alt="Java Srcipt"
                  className="w-100 h-40"
                />
                <h5>Java Srcipt</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/SQL-Lite.webp"
                  alt="SQL Lite"
                  className="w-100 h-40"
                />
                <h5>SQL Lite</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/html.webp"
                  alt="HTML"
                  className="w-100 h-40"
                />
                <h5>HTML</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/android.webp"
                  alt="Android"
                  className="w-100 h-40"
                />
                <h5>Android</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mongo-db-icon.webp"
                  alt="Mongo DB"
                  className="w-100 h-40"
                />
                <h5>Mongo DB</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/app-store.webp"
                  alt="App Store"
                  className="w-100 h-40"
                />
                <h5>App Store</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/atom.webp"
                  alt="Atom"
                  className="w-100 h-40"
                />
                <h5>Atom</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/laravel.webp"
                  alt="Laravel"
                  className="w-100 h-40"
                />
                <h5>Laravel</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5>Python</h5>
              </div>
            </div>
          </div>
        </section>
        <section className="why-MetaBlock-hyp">
          <div className="container">
            <div className="text-center why_hyp_content grident-color-sub-title mb-5">
              <h2 className="hyp_common_title">
                Why MetaBlock for Static Website Development?
              </h2>
            </div>
            <div className="row mar-top-30">
              <div className="col-md-6 col-sm-6">
                <div className="list_item">
                  <ul className="list-unstyled">
                    <li>
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="Customizable Static Website Solutions"
                      />
                      Customizable Static Website Solutions.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="Professionals backed by 3+ years of industry experience"
                      />
                      Professionals backed by 3+ years of industry experience.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="Direct collaboration with the technical team"
                      />
                      Direct collaboration with the technical team.{" "}
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="Precise quality assurance process"
                      />
                      Precise quality assurance process.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 ">
                <div className="list_item">
                  <ul className="list-unstyled">
                    <li>
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt=" Websites built on leading-edge software packages"
                      />
                      Websites built on leading-edge software packages.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="Integration of genuine API tools"
                      />
                      Integration of genuine API tools.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="Dedicated team for projects"
                      />
                      Dedicated team for projects.{" "}
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        className="hyp-tic-sprite"
                        alt="On-time delivery at an affordable cost"
                      />
                      On-time delivery at an affordable cost.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <SimilarFile />
        </div>
        <FAQSection2 category="static"/>
      </div>
    </>
  );
};

export default Static;
