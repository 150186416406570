import React from 'react'

const OurStepByStep = () => {
  return (
    <div>
      <section className="process_we_follow section-heading-1">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="cms text-center Process">
                  <h3 className="our-app">
                    Our Step-By-Step Software Development Process
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <img
                  src="/images/process-flow-new.webp"
                  alt="process-flow-new"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default OurStepByStep
