const keyData = {
  software: [
    {
      id: 1,
      title: "User  Interface",
      description: "A Good UI enables users to navigate and use software or devices easily without confusion.",
      image: "./images/user-interface.webp",
    },
    {
      id: 2,
      title: "Real-Time Updates",
      description: "It means technology will continuously give updates and information from time to time.",
      image: "./images/system-update.webp",
    },
    {
      id: 3,
      title: "Customization Options",
      description: "Allowing to modify and customize softwares for your reliable occupations and companies.",
      image: "./images/customization.webp",
    },
    {
      id: 4,
      title: "Data Security",
      description: "Data security gives protection for unauthorized users' access, corrupt files/data, theft, or losses. Data security is helpful in internal systems/technology.",
      image: "./images/encrypted.webp",
    },
    {
      id: 5,
      title: "Scalability",
      description: "An ability to measure the system control. Provide a report of work and show the losses and profits. Gives the necessary advice for improvements.",
      image: "./images/user-interface.webp",
    },
    {
      id: 6,
      title: " Automation Features",
      description: "The feature helps reduce errors, performs tasks automatically without the help of humans, and saves the time of the system. ",
      image: "./images/system-update.webp",
    },
    {
      id: 7,
      title: "Collaboration Tools",
      description: "Collaboration – means community. A collaboration tool is a digital platform designed to help teams work together seamlessly, no matter where they are.",
      image: "./images/customization.webp",
    },
    {
      id: 8,
      title: "Analytics and reports",
      description: "Data backup is the process of copying important files to avoid loss due to system failure, theft, or damage, ensuring information can be restored.",
      image: "./images/encrypted.webp",
    },
  ],
  erp: [
    {
      id: 1,
      title: "Inventory Monitoring",
      description: "Inventory monitoring tracks stock levels, ensuring optimal supply, reducing waste, and improving operations for efficient resource management.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: "Make Better Decision",
      description: "Make better decisions by analyzing options, considering long-term effects, trusting your intuition, and learning from past experiences.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: "Remote Access",
      description: "Remote access allows users to connect to a computer or network from a distant location, enabling efficient work and data access without being physically present.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: "Comfortable Sales System",
      description: "A Comfortable Sales System streamlines transactions, enhances customer experience, and boosts efficiency, ensuring a seamless process from start to finish.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: "Versatile Service",
      description: "Versatile service offers flexible solutions to meet diverse needs, ensuring efficiency, adaptability, and quality across various industries and customer requirements.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Bulk Data Insert",
      description: "Bulk data insert is a process of adding large volumes of data to a database efficiently, reducing time and enhancing performance for large datasets.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "Spectacular Dashboard",
      description: "A spectacular dashboard offers intuitive design, real-time data, and interactive features, enabling users to easily track, analyze, and visualize key metrics.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Data Backup",
      description: "Data backup is the process of copying important files to prevent loss due to system failure, theft, or damage, ensuring information can be restored.",
      image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
  
};

export default keyData;





// erp: [
//   {
//     id: 1,
//     title: "Inventory Monitoring",
//     description: "Inventory monitoring tracks stock levels, ensuring optimal supply, reducing waste, and improving operations.",
//     image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
//   },
//   {
//     id: 2,
//     title: "Make Better Decision",
//     description: "Make better decisions by analyzing options, considering long-term effects, trusting your intuition.",
//     image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
//   },
//   {
//     id: 3,
//     title: "Remote Access",
//     description: "Remote access allows users to connect to a computer or network from a distant location.",
//     image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
//   },
//   {
//     id: 4,
//     title: "Comfortable Sales System",
//     description: "A Comfortable Sales System streamlines transactions, enhances customer experience, and boosts efficiency.",
//     image: "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
//   },
// ],