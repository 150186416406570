import React from "react";
import "../privacyPolicy/PrivacyPolicy.css";

const TermAndCondition = () => {
  return (
    <>
      <div className="container-div-1">
        <div className="containar-box-1" style={{ width: "70%" }}>
          <div className="terms-container">
            <div className="section">
              <h1>Term & Conditions</h1>
              <h2>Software Development Agreement</h2>
              <p>
                Software Development Agreement ("Agreement") is made and entered
                into on Finial Date by and between:{" "}
              </p>
              <h3>First Party: MetaBlock Technologies LLP ("Company")</h3>
              <p>
                A company incorporated under the laws of government laws or
                regulations, with its registered office at 36/12, Kiran Path,
                Mansarovar, Jaipur, Rajasthan, 302020, India. The Company
                reserves the right to immediately suspend or terminate the
                operation. It is not the Company’s responsibility to monitor or
                track the Client’s activities, but upon receiving such
                information, the Company will take appropriate action.
              </p>

              <h3>Second Party: Customer Name ("Client")</h3>
              <p>With its principal place of business.</p>

              <h2>Purpose of the Agreement</h2>
              <p>
                The purpose of this Agreement is to establish the terms and
                conditions under which MetaBlock Technologies LLP (the
                "Company") will provide development services to the Client for
                the creation of a game website. This website may include, but is
                not limited to, games involving elements of skill, chance,
                betting, slot games, and style games, as specified by the
                Client. The Company’s role is strictly confined to the technical
                development and delivery of the game website in accordance with
                the Client's specifications.
              </p>
              <p>
                The Company shall have no involvement in, nor responsibility
                for, any operational, financial, legal, or regulatory aspects of
                the website’s use or management after delivery. It is expressly
                understood that the Company does not assume any responsibility
                to ensure that the games or the game website comply with any
                local, state, national, or international laws or regulations,
                including but not limited to gambling laws, betting laws, ga
              </p>
              <h2>Legal Compliance and Responsibilities</h2>
              <p>
                The Company shall have no involvement in, nor responsibility
                for, any operational, financial, legal, or regulatory aspects of
                the website’s use or management after delivery. It is expressly
                understood that the Company does not assume any responsibility
                to ensure that the games or the game website comply with any
                local, state, national, or international laws or regulations.
              </p>
              <h2>1. Local, National, and International Laws: </h2>
              <p>
                The Company is not responsible for ensuring the game website
                complies with any legal requirements related to gambling,
                betting, or gaming in any jurisdiction. This includes, but is
                not limited to:
              </p>
              <strong>◦ The Public Gambling Act, 1867.</strong>
              <strong>◦ The Rajasthan Public Gaming Ordinance, 1949.</strong>
              <strong>◦ The Information Technology (IT) Act, 2000. </strong>
              <strong>
                ◦ The Rajasthan Prevention of Gambling Ordinance, 1949.{" "}
              </strong>
              <strong>◦ Internet and Online Gaming Regulations. </strong>
              <strong>
                ◦ Intellectual Property Laws (e.g., Copyright Act, 1957 and
                Patents Act, 1970).{" "}
              </strong>
              <strong>◦ Consumer Protection Act, 2019. </strong>
              <strong>◦ RBI Guidelines for Online Payments.</strong>
              <strong>◦ Children's Online Privacy Protection Act.</strong>
              <strong>◦ Law For Online Transaction.</strong>
              <strong>
                ◦ Guidelines from Ministry of Information and Broadcasting and
                Ministry of Electronics and Information Technology (MeitY).
              </strong>
              <strong>
                ◦ International Licensing Requirements such as those set by the
                UK Gambling Commission, Curacao eGaming, and Malta Gaming
                Authority (MGA).{" "}
              </strong>

              <h2>Licensing and Compliance</h2>
              <p>
                It is the Client’s sole responsibility to obtain and maintain
                all necessary licenses, permits, and approvals required for
                operating the website and its games in any jurisdiction where
                they intend to operate. The Company will not be responsible for
                verifying the legality of the website's operations or the
                validity of any licenses or permits obtained by the Client. If
                the Client acquires certification or licensing for a specific
                region but does not have the requisite permissions for other
                regions, it is the Client’s responsibility to restrict access to
                the website accordingly, ensuring it is only available in
                regions where they hold valid certifications or licenses. Just
                like e.g.If the Client acquires certification or licensing to
                operate their game website in a specific region, such as New
                Jersey, USA, Sweden,United Kingdom Singapore, but does not have
                the required permissions or licenses to operate in other regions
                like Telangana and Andhra Pradesh, India etc., it is solely the
                Client's responsibility to ensure that access to the website is
                restricted accordingly. This means that the Client must take all
                necessary measures, such as geoblocking or IP restrictions, to
                ensure that users from regions where they do not hold valid
                licenses, such as India, cannot access or use the website.
                Failure to restrict access to regions where the Client does not
                have legal approval could result in legal consequences. However,
                the Company bears no responsibility for monitoring or
                implementing these restrictions. It is entirely the Client's
                duty to ensure compliance with local and international gaming
                laws in the jurisdictions where their website is made available.
              </p>

              <h2>3. Legal and Regulatory Advice:</h2>
              <p>
                {" "}
                The Company does not provide any legal, regulatory, or
                compliance advice regarding the development or operation of the
                game website. The Client is advised to seek independent legal
                counsel or a regulatory expert to ensure that their website
                complies with all relevant laws and regulations, including but
                not limited to gaming, gambling, and betting laws, as well as
                data protection and privacy laws, tax laws (such as GST
                certification and TDS laws), and other applicable regulations.{" "}
              </p>
              <h2>4. Client’s Full Responsibility:</h2>
              <p>
                The Client acknowledges and agrees that they are fully
                responsible for the legal compliance, management, and operation
                of the game website once development is complete. This includes
                all aspects related to regulatory compliance, user safety,
                financial transactions, and dispute resolution with users or
                government authorities. The Company will not be liable for any
                claims, damages, losses, or legal actions arising from the use
                or misuse of the game website by the Client or its users.
              </p>

              <h2>5. Non-Involvement in Operations and Legal Compliance:</h2>
              <p>
                The Company is not a partner, co-owner, or stakeholder in the
                game website or its operations. The Company’s responsibilities
                are strictly limited to the technical aspects of development as
                per the Client’s instructions. All operational decisions,
                including those related to legal compliance, user engagement,
                financial management, and dispute handling, are solely the
                Client’s responsibility.
              </p>
              <h2>6. Indemnification and Liability:</h2>
              <p>
                :The Client agrees to indemnify, defend, and hold harmless
                MetaBlock Technologies LLP (the "Company") from any and all
                claims, damages, losses, liabilities, and expenses, including
                reasonable attorney fees, arising out of or in connection with
                the operation or use of the game website. This includes but is
                not limited to any illegal activities, non-compliance with
                applicable local, national, or international laws, fraud, or any
                disputes with users, government authorities, or third parties.
              </p>

              <p>
                The Client acknowledges that it is fully and solely responsible
                for ensuring the legal compliance of the game website and its
                operations. The Client further agrees that the Company will not
                be held accountable for any illegal activity, regulatory
                violations, or legal disputes conducted through or related to
                the game website. Any legal consequences arising from the
                operation of the website, including fines, claims, or lawsuits
                from users or government authorities, are the complete
                responsibility of the Client.{" "}
              </p>

              <p>
                Note -If it comes to the Company’s attention, either through a
                third party or a government entity, that the Client is operating
                the website or game in violation of government laws or
                regulations, the Company reserves the right to immediately
                suspend or terminate the operation. It is not the Company’s
                responsibility to monitor or track the Client’s activities, but
                upon receiving such information, the Company will take
                appropriate action.
              </p>

              <p>
                government laws or regulations, the Company reserves the right
                to immediately suspend or terminate the operation. It is not the
                Company’s responsibility to monitor or track the Client’s
                activities, but upon receiving such information, the Company
                will take appropriate action.
              </p>

              <h2>Definition of Roles:</h2>
              <p>
                {" "}
                This section outlines the specific roles and responsibilities of
                MetaBlock Technologies LLP (hereinafter referred to as the
                "Company") and the Client (hereinafter referred to as the
                "Client") in relation to the development of a game website.
              </p>

              <h2>1. Role of the Company:</h2>

              <p>
                The Company, MetaBlock Technologies LLP, is engaged solely to
                provide technical development services as per the Client’s
                specifications. These services include the design, coding,
                integration and delivery of the game website.
              </p>
              <p>
                The Company is not involved in the operational, financial,
                regulatory, or managerial aspects of the game website. Its sole
                responsibility is to deliver a technically functional website
                that meets the Client’s development specifications.
              </p>
              <p>
                The Company does not provide any warranty, guarantee, or
                assurance regarding the legal compliance, financial viability,
                or market success of the game website or any of its components.
                The Company’s involvement is limited strictly to the technical
                development of the website.
              </p>
              <p>
                The Company is not a partner, co-owner, or stakeholder in the
                game website or any business operations related to the website.
                It holds no interest, financial or otherwise, in the game
                website beyond the scope of development services provided under
                this Agreement.
              </p>
              <p>
                The Company does not assume any responsibility for obtaining
                licenses, permits, certifications, or regulatory approvals
                required for the operation of the game website. The Company’s
                role does not include any legal or regulatory advisory services,
                and it does not provide guidance or advice on compliance with
                gaming, betting, or gambling laws, nor with other local, state,
                national, or international regulations.
              </p>

              <h2>2. Role of the Client:</h2>
              <p>
                - The Client, <strong>HYBRID WEBTECH</strong>, is solely
                responsible for providing the Company with complete, accurate,
                and lawful specifications and requirements for the development
                of the game website. The Client must ensure that all content,
                features, and functionalities in the website are in compliance
                with all applicable laws and regulations in the jurisdictions
                where client want to operate the website.
              </p>

              <h2> Legal Compliance for Games:</h2>
              <p>
                <strong>• Game Legality:</strong>• Game Legality: The Client is
                solely responsible for verifying the legality of any games they
                wish to develop and deploy on the website. This includes
                ensuring that all games, whether skill-based, chance-based,
                betting, slot, or style, comply with applicable local, national,
                and international gaming laws and regulations.{" "}
              </p>

              <p>
                <strong>• Regulatory Compliance:</strong> The Client is solely
                responsible for ensuring that the website and all games comply
                with all applicable laws and regulations, including but not
                limited to, gaming laws, gambling regulations, data protection
                laws, and other relevant legal requirements. The Client must
                obtain all necessary licenses, permits, and approvals required
                for operating the website and its games in their jurisdiction.
              </p>
              <h3>Necessary Documentation:</h3>

              <p>
                <strong>• Licenses and Permits: </strong>The Client is
                responsible for acquiring and maintaining all necessary
                licenses, permits, and legal documentation required for
                operating the website and its games in their jurisdiction. This
                includes but is not limited to, gaming licenses, gambling
                permits,GST certifications and any other regulatory
                certifications.{" "}
              </p>
              <p>
                <strong>• Legal Certifications:</strong> It is the Client's duty
                to secure any legal certifications or approvals needed for the
                website's operation and to ensure that these documents are valid
                and up-to-date. The Company will not assist in obtaining or
                verifying such certifications.{" "}
              </p>

              <h3>Indemnification and Liability</h3>
              <p>
                <strong>• Indemnification and Liability:</strong> The Client
                agrees to indemnify and hold harmless the Company from any and
                all claims, damages, losses, liabilities, and expenses,
                including reasonable attorney fees, arising out of or in
                connection with the Client’s use of the game website, including
                but not limited to any violation of applicable laws or
                regulations, or any claims made by users or government entities.
                The Client acknowledges that it is 100% responsible for ensuring
                that its operations are legal and that it will not hold the
                Company accountable for any illegal activity conducted through
                the game website.
              </p>
              <p>
                <strong>• No Warranty on Legality:</strong>
                The Company makes no representations or warranties regarding the
                legality of the game website or its content in any jurisdiction.
                The Client accepts full responsibility for ensuring that the
                game website complies with all relevant laws and regulations.
              </p>
              <p>
                <strong>• Non-Involvement in Operations:</strong>
                The Company explicitly states that it is not a partner,
                co-owner, or stakeholder in the game website. The Company’s sole
                function is to provide technical development services. All other
                aspects, including legal compliance and business operations, are
                the exclusive responsibility of the Client.
              </p>
              <p>
                <strong>• Disputes with End Users or Authorities:</strong>
                The Client acknowledges that any disputes, complaints, or legal
                actions arising from end users or authorities related to the
                game website, including issues of non-payment, fraud, or
                regulatory breaches, are solely the responsibility of the
                Client. The Company shall not be liable for any claims or
                disputes arising from the Client’s management or operation of
                the game website.
              </p>
              <p>
                <strong>• GGR Payment and Liability:</strong>
              </p>
              <p>
                - The Client is responsible for paying the Gross Gaming Revenue
                (GGR) either directly to the game provider or through the
                Company, as per the agreed terms with the game provider.
              </p>
              <p>
                - The Company may apply on behalf of the Client to the game
                provider for GGR payments. However, if any legal action is taken
                against the Company by the game provider due to the Client's
                failure to pay GGR or other related issues, the Client will bear
                full responsibility.
              </p>
              <p>
                - The Client agrees to indemnify and hold the Company harmless
                from any legal claims, penalties, or consequences arising from
                the Client's failure to pay GGR or any related disputes,
                including any potential legal actions taken against the Company.
              </p>
              <p>
                - The Company is not a partner in the game and is only
                facilitating the application process for the Client. The Client
                remains solely responsible for all GGR payments and associated
                liabilities. This clause clarifies the Client’s responsibilities
                and protects the company from any legal repercussions related to
                GGR payments.
              </p>

              <p>
                <strong>Legal Advice:</strong>
              </p>
              <p>
                <strong>• Professional Consultation:</strong> The Client is
                encouraged to consult with legal professionals to ensure full
                compliance with all applicable laws and regulations. The Company
                does not provide legal advice or assume any responsibility for
                the Client’s compliance with legal requirements.
              </p>
              <p>
                <strong>• Responsibility for Non-Compliance:</strong> Should the
                Client face legal issues or regulatory actions due to
                non-compliance with relevant laws, the Company will not be held
                liable. The Client is responsible for any legal consequences
                arising from the operation of the website and its games.
              </p>

              <p>
                <strong>Operation and Management:</strong>
              </p>
              <p>
                <strong>• Operational Compliance:</strong> The Client is
                responsible for the operational aspects of the website,
                including ongoing management, updates, and adherence to legal
                standards. The Company’s involvement is limited to the
                development of the website as per the agreed specifications and
                does not extend to operational oversight or legal compliance
                post-delivery.
              </p>

              <p>
                <strong>User Complaints:</strong> The Client is responsible for
                addressing and resolving all user complaints related to the
                website. The Company shall not be involved in or responsible for
                handling any user issues or disputes.
              </p>

              <p>
                <strong>Fraud and Scams:</strong> The Client assumes full
                responsibility for any fraudulent activities or scams occurring
                on the website. The Company shall not be liable for any losses
                or damages resulting from such activities.
              </p>

              <p>
                <strong>Payment Disputes:</strong> The Client is responsible for
                managing and resolving any financial disputes between users and
                the website. The Company will not handle or be liable for
                financial transactions or payment issues.
              </p>

              <p>
                <strong>Consumer Protection:</strong> The Client agrees to
                adhere to all applicable consumer protection laws and ensure
                that their website complies with relevant regulations regarding
                user interactions and financial transactions.
              </p>

              <p>
                <strong>Legal Recourse:</strong> The Client agrees to handle any
                legal claims or disputes arising from user complaints. The
                Company shall be indemnified against any legal actions or
                penalties resulting from such claims.
              </p>

              <p>
                <strong>User Data and Privacy Issues:</strong> The Client is
                responsible for complying with data protection laws and ensuring
                the security of user data. The Company shall not be liable for
                any data breaches or privacy issues related to the website.
              </p>

              <p>
                <strong>Regulatory Compliance:</strong> The Client is
                responsible for ensuring that the website meets all relevant
                gaming regulations and reporting requirements. The Company does
                not provide regulatory compliance beyond the scope of
                development services.
              </p>

              <p>
                <strong>User Authentication and Security:</strong> The Client
                must implement and manage secure user authentication procedures.
                The Company is not responsible for security vulnerabilities or
                user authentication issues after the website is delivered.
              </p>

              <p>
                <strong>Age Restriction:</strong> The Client agrees to ensure
                that the game website is only accessible to individuals who are
                considered adults according to the legal age of adulthood in
                their respective jurisdiction. It is the Client's sole
                responsibility to implement and enforce age verification
                measures on the website to restrict access to minors. The
                Company will not be responsible for verifying or enforcing age
                restrictions, and any failure by the Client to comply with this
                requirement will be solely the Client's responsibility.
              </p>

              <p>
                <strong>User Interaction and Content Management:</strong> The
                Client is responsible for moderating user-generated content and
                managing user interactions on the website. The Company shall not
                be involved in content moderation or user conduct enforcement.
              </p>

              <p>
                <strong>Exclusion of Claims:</strong> Users and government
                authorities acknowledge that they have no claim against the
                Company for issues arising from the website’s operation. All
                responsibilities and liabilities remain with the Client.
              </p>

              <p>
                <strong>Duty to Address Legal Issues:</strong> The Client agrees
                to handle all legal issues related to the website, including
                legal representation and associated costs. The Company shall not
                be responsible for managing or participating in legal matters.
              </p>

              <p>
                If it comes to the Company’s attention that the Client is
                operating the website or game in violation of government laws or
                regulations, the Company reserves the right to immediately
                suspend or terminate the operation.
              </p>
            </div>

            {/* <div className="section">
        <h2>Applicable Laws and Regulations</h2>
        <p>
          The Client is responsible for ensuring compliance with all applicable laws, including 
          but not limited to:
        </p>
        <ul>
          <li>The Public Gambling Act, 1867</li>
          <li>The Rajasthan Public Gaming Ordinance, 1949</li>
          <li>The Information Technology (IT) Act, 2000</li>
          <li>RBI Guidelines for Online Payments</li>
          <li>International Licensing Requirements</li>
        </ul>

        <h2>Client’s Full Responsibility</h2>
        <p>
          The Client acknowledges and agrees that they are fully responsible for the legal 
          compliance, management, and operation of the game website once development 
          is complete. This includes all aspects related to regulatory compliance, user safety, 
          financial transactions, and dispute resolution with users or government authorities.
        </p>
      </div> */}
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default TermAndCondition;
