// industriesData.js
const industriesData = {
  software: [
    {
      id: 1,
      title: "Manufacturing",
      description: "Software development manufacturing streamlines coding, testing, and deployment using systematic processes, tools, and automation for quality delivery.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Retail",
      description: "Retail in software development involves creating, customizing, or selling software solutions tailored for retail businesses to enhance operations.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "Healthcare",
      description: "Healthcare software development enhances patient care, streamlines processes, ensures data security, and drives innovation in medical technologies.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Finance",
      description: "Effective software development finance involves budgeting, resource allocation, cost control, ROI analysis, and optimizing development efficiency.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: "Education",
      description: "Software development education equips learners with coding, problem-solving, and design skills to build, test, and maintain software applications.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: "Human Resources",
      description: "Effective HR in software development focuses on talent acquisition, skill development, team collaboration, and employee well-being for success.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Project Management",
      description: "Efficient software development requires planning, team collaboration, resource management, and agile methodologies to deliver quality solutions.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Graphic Design",
      description: "Graphic design in software development enhances user interfaces, ensuring visually appealing and intuitive experiences that improve usability and engagement.",
      image: "./images/graphic-designer.webp",
    },
  ],
  erp: [
    {
      id: 1,
      title: "Manufacturing",
      description: "ERP software manufacturers are experts in developing solutions that integrate business processes, enhancing efficiency & decision-making across various industries.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Retail",
      description: "ERP software for retail smooth-out operations enhances inventory management, improves customer satisfaction, & improves decision-making with real-time data.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "Healthcare",
      description: "ERP software in healthcare refines processes improves patient care, manages resources, & ensures compliance, boosting efficiency & reducing costs.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Finance",
      description: "ERP software development requires essential investment in research, design, coding, testing, & ongoing maintenance to ensure scalability, security, & integration.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: "Education",
      description: "ERP software development education focuses on programming, business processes, and system integration, preparing professionals to build efficient enterprise solutions.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: "Human Resources",
      description: "Human resources in ERP software development focus on skilled developers, project managers, and testers who collaborate to create efficient, customized solutions.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Project Management",
      description: "Effective project management in ERP software development ensures clear goals, efficient resource allocation, and timely delivery, driving business process integration.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Graphic Design",
      description: "Graphic design in ERP software development enhances user experience with intuitive interfaces, improving usability and visual appeal for better system interaction.",
      image: "./images/graphic-designer.webp",
    },
  ],
};

export default industriesData;