import React from 'react'
import './WhyITServiceImportant.jsx.css'
import WhyITServiceImportantSlider from './WhyITServiceImportantSlider'
const WhyITServiceImportant = () => {
    return (
        <>
            <div className="m-0">
                <div className="dashboard-main-box">
                    <h2 className="Key-feature-heading mb-5 text-center" style={{ fontSize: '45px' }}>
                        Why IT Service are Essential  <br /> For Business Success
                    </h2>
                    <p>Business operations require IT services as an important ingredient of growth. They assist<br/> organizations to display their corporate image, gain confidence, establish credibility,<br/> be always reachable and prospect for customers for which they are so relevant<br/> to any business endeavor in today’s advanced marketplace.</p>
                    <div className="why-it-seriveces-box">
                        <div className="why-it-service-important ">

                            <img src="./images/brandShowcasing.webp" alt="Brand advertising With metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Brand Showcasing</h3>
                        </div>

                        <div className="why-it-service-important ">

                            <img src="./images/credibility.webp" alt="Credibilty | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title"> Credibility</h3>
                        </div>
                        <div className="why-it-service-important ">

                            <img src="./images/availability.webp" alt="Availability | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">24 * 7 Availability</h3>
                        </div>
                        <div className="why-it-service-important ">

                            <img src="./images/leadGeneration.webp" alt="Lead Generation with metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Lead Generation</h3>
                        </div>
                    </div>
                    <div className="why-it-seriveces-box">
                        <div className="why-it-service-important ">

                            <img src="./images/custmerConvinience.webp" alt="Custer Convinience | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Customer Convenience</h3>
                        </div>
                        <div className="why-it-service-important ">

                            <img src="./images/organicTraffic.webp" alt="Boost Organic traffic | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Organic Traffic</h3>
                        </div>
                        <div className="why-it-service-important ">

                            <img src="./images/globalReach.webp" alt="Global Reach With metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Global Reach</h3>
                        </div>
                        <div className="why-it-service-important ">

                            <img src="./images/brandAwareness(2).webp" alt="Brand Awareness With metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Brand Awardness</h3>
                        </div>
                    </div>
                    <div className="dashboard-container">
                        <div className="why-it-service-important ">

                            <img src="./images/costEffective(3).webp" alt="Cost Effective Services  | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Cost Effective Advertising</h3>
                        </div>
                        {/* <div className="why-it-service-important ">

                            <img src="./images/fullDesignControl.jpg" alt="" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Cost Effective Advertising</h3>
                        </div> */}
                        <div className="why-it-service-important ">

                            <img src="./images/fullDesignControl-1.webp" alt="Full Design Control | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className=" grident-color-sub-title">Full Design Control</h3>
                        </div>
                        <div className="why-it-service-important ">
                            <img src="./images/security-for.webp" alt="Security | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className="grident-color-sub-title">Security</h3>
                        </div>
                        <div className="why-it-service-important ">
                            <img src="./images/support-for.webp" alt="24/7 Support  | metablocktechnologies" />
                            <h3 style={{ textAlign: 'center' }} className="grident-color-sub-title">Customer Support</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyITServiceImportant



