import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";
import SAASSwiperSlider from "./SwiperSlider/SAASSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

// import '../../main.css'

const SAASSoftware = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    {/* Blockchain Finance Management */}SAAS Software
                    Development
                  </h1>
                  <h3 className="heading-tagline">
                    SAAS software development revolutionizes modern business by
                    offering scalable, subscription-based solutions accessible
                    from any device with internet connectivity. Its agile
                    development framework empowers businesses to swiftly deploy,
                    customize, and scale software applications, driving
                    efficiency and innovation in today's dynamic market
                    landscape.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in SaaS (Software as a Service) software, our
                focus is on delivering cutting-edge solutions that cater to the
                evolving needs of modern businesses. With our expertise, we
                provide cloud-based applications that offer scalability,
                flexibility, and ease of use. Our SaaS offerings are designed to
                streamline processes, boost productivity, and drive growth for
                our clients across various industries. From customer
                relationship management to project management and beyond, we are
                dedicated to delivering innovative SaaS solutions that empower
                organizations to thrive in today's digital landscape.
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/devanshusoftware-2.jfif.webp" alt="devanshusoftware" />
            </div>
          </div>
        </section>
        <SAASSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
          <CardSlider/>
        </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
          <Phonecardslider />
        </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default SAASSoftware;
