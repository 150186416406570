import React, { useState, useEffect } from "react";
import axios from "axios";

function Blog_Topic() {
  const [topics, setTopics] = useState([]);
  const [formData, setFormData] = useState({ name: "" });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org//api/v1/topic"
      );
      setTopics(response.data);
    } catch (error) {
      console.error("Error fetching the topics", error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axios.put(
          `https://maincompanybackend.metablocktechnologies.org//api/v1/topic/${editId}`,
          formData
        );
        setEditMode(false);
        setEditId(null);
      } else {
        await axios.post(
          "https://maincompanybackend.metablocktechnologies.org//api/v1/topic/create",
          formData
        );
      }
      setFormData({ name: "" });
      fetchTopics();
    } catch (error) {
      console.error("Error saving the topic", error);
    }
  };

  const handleEdit = (topic) => {
    setEditMode(true);
    setEditId(topic._id);
    setFormData({ name: topic.name });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://maincompanybackend.metablocktechnologies.org//api/v1/topic/${id}`
      );
      fetchTopics();
    } catch (error) {
      console.error("Error deleting the topic", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">More Topics</h1>
      <form
        onSubmit={handleFormSubmit}
        className="max-w-md mx-auto bg-white p-4 rounded-md shadow-md mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-md text-black"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          {editMode ? "Update Topic" : "Add Topic"}
        </button>
      </form>
      <table className="min-w-full bg-white text-black border border-gray-300">
        <thead>
          <tr className="bg-slate-500 border-gray-300">
            <th className="py-2 px-4 border border-gray-300 text-black">
              Sr No.
            </th>
            <th className="py-2 px-4 border border-gray-300 text-black">
              Name
            </th>
            <th className="py-2 px-4 border border-gray-300 text-black">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => (
            <tr key={topic._id}>
              <td className="py-2 px-4 border border-gray-300 text-black font-bold">
                {index + 1}
              </td>
              <td className="py-2 px-4 border border-gray-300 text-black font-bold">
                {topic.name}
              </td>
              <td className="py-2 px-4 border border-gray-300">
                <button
                  onClick={() => handleEdit(topic)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded-md mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(topic._id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Blog_Topic;
