import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import HRMSSwiperSlider from "./SwiperSlider/HRMSSwiperSlider";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import TechnologyStack from "../../../components/TechnologyStack";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const HRMSSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    {/* Blockchain Finance Management */}HRMSS Software
                    Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    HRMS (Human Resource Management System) Software simplifies
                    HR tasks by automating payroll, recruitment, attendance
                    tracking, and performance evaluation processes. With its
                    user-friendly interface and comprehensive features, HRMS
                    software empowers organizations to efficiently manage their
                    human capital, fostering productivity and organizational
                    growth.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in HRMS (Human Resource Management System)
                software, our focus is on providing comprehensive solutions to
                streamline and enhance human resource operations within
                organizations. Our software is designed to automate various HR
                tasks, including employee onboarding, payroll processing,
                performance management, and benefits administration. With our
                expertise, businesses can efficiently manage their workforce,
                track employee data, and ensure compliance with regulations. Our
                goal is to empower HR professionals with the tools they need to
                effectively support their organization's talent management
                strategies and foster a productive and engaged workforce.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/devanshusoftware-4.jfif.webp" alt="managmentsoft" />
              managmentsoft
            </div>
          </div>
        </section>

        <HRMSSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
        </section>
        <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />
        <section>
          <SimilarFile />
        </section>
        <FAQSection2 category="hrmsoftware"/>
      </div>
    </>
  );
};

export default HRMSSoftware;
