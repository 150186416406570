import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import POSSwiperSlider from "./SwiperSlider/POSSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const POSSystemSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    POS System Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    POS test System Software facilitates seamless transactions and
                    inventory management, enhancing customer service and
                    operational efficiency for businesses. With its intuitive
                    interface and real-time analytics, it enables retailers to
                    track sales, manage inventory, and analyze customer
                    behavior, optimizing their overall retail experience and
                    driving business growth.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in POS (Point of Sale) system software, our
                expertise lies in providing efficient solutions to streamline
                retail operations and enhance customer experiences. Our software
                is meticulously designed to handle transactions, manage
                inventory, and track sales data in real-time. With our
                expertise, businesses can optimize their checkout processes,
                reduce errors, and improve overall efficiency. Whether it's a
                small boutique or a large chain store, our POS system software
                is tailored to meet the unique needs of each client, helping
                them boost sales, improve customer satisfaction, and drive
                growth in their business.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/possoft.webp" alt="possoft" />
            </div>
          </div>
        </section>

        <POSSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
        </section>


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default POSSystemSoftware;
