// chooseData.js
const chooseData = [
  {
    category: "software",
    heading: "Why choose us in software development?",
    description:
      "Partner with us for innovative software development, as we customize scalable solutions from scratch according to your needs.",
    slides: [
      {
        title: "In-House Development",
        content:
          "In-house development refers to creating software or systems using a Network’s internal team, ensuring full control over processes, customization, & data security. It fosters collaboration, & aligns with company goals, but may require Important time, resources, & technical expertise.",
      },
      {
        title: "Using Latest Technologies",
        content:
          "Adopting the latest technology empowers organizations to raise productivity, optimize efficiency, and foster faster innovation. By implementing modern tools, software, and systems, businesses can fine-tune their processes, create exceptional products, and keep ahead of their competitors.",
      },
      {
        title: "Testing Facility",
        content:
          "A testing facility is a specific environment designed for the comprehensive evaluation of products, software, or systems to ensure they meet quality and performance standards. It supplies the essential resources and tools for various testing types, such as functional, security, and stress testing.",
      },
      {
        title: "After Sale Support",
        content:
          "After-sale support refers to the help offered to customers after they buy a product or service. This support encompasses troubleshooting, product installation, maintenance, updates, and resolving any questions or problems that may come up.",
      },
      {
        title: "Post Payment Term",
        content:
          "Post-payment terms refer to agreements where buyers pay for goods or services after delivery or completion, typically within a specified period, such as 30 or 60 days. These terms foster trust, flexibility, and smoother cash flow management for organizations.",
      },
      {
        title: "On-Time Project Delivery",
        content:
          "On-time project delivery ensures tasks are completed within the agreed timeframe, fostering trust and efficiency. It enhances client satisfaction, minimizes costs, & maintains project quality by adhering to deadlines through effective planning, resource management, & clear communication.",
      },
      {
        title: "Expert Team and Religious Team",
        content:
          "An expert team is basically a group of highly qualified professionals who have tremendous thorough knowledge within their fields as well as experience. They work together to produce high-quality solutions, offering specialized insights and creative approaches to complex challenges.",
      },
      {
        title: "Cost Effective Development",
        content:
          " Cost-effective development focuses on maximizing value while minimizing costs. It involves efficient resource allocation, adopting scalable technologies, & leveraging agile methodologies. Prioritizing essential features, reusing components, & optimizing workflows ensure high-quality results without overspending, fostering sustainable growth and innovation.",
      },
    ],
  },
  {
    category: "erp",
    heading: "Why choose us for ERP solutions?",
    description:
      "Our ERP solutions help businesses automate processes, improve data visibility, and boost productivity across all departments.",
      slides: [
        {
          title: "In-House Development",
          content:
            "In-house development refers to creating software or systems using a Network’s internal team, ensuring full control over processes, customization, & data security. It fosters collaboration, & aligns with company goals, but may require Important time, resources, & technical expertise.",
        },
        {
          title: "Using Latest Technologies",
          content:
            "Adopting the latest technology empowers organizations to raise productivity, optimize efficiency, and foster faster innovation. By implementing modern tools, software, and systems, businesses can fine-tune their processes, create exceptional products, and keep ahead of their competitors.",
        },
        {
          title: "Testing Facility",
          content:
            "A testing facility is a specific environment designed for the comprehensive evaluation of products, software, or systems to ensure they meet quality and performance standards. It supplies the essential resources and tools for various testing types, such as functional, security, and stress testing.",
        },
        {
          title: "After Sale Support",
          content:
            "After-sale support refers to the help offered to customers after they buy a product or service. This support encompasses troubleshooting, product installation, maintenance, updates, and resolving any questions or problems that may come up.",
        },
        {
          title: "Post Payment Term",
          content:
            "Post-payment terms refer to agreements where buyers pay for goods or services after delivery or completion, typically within a specified period, such as 30 or 60 days. These terms foster trust, flexibility, and smoother cash flow management for organizations.",
        },
        {
          title: "On-Time Project Delivery",
          content:
            "On-time project delivery ensures tasks are completed within the agreed timeframe, fostering trust and efficiency. It enhances client satisfaction, minimizes costs, & maintains project quality by adhering to deadlines through effective planning, resource management, & clear communication.",
        },
        {
          title: "Expert Team and Religious Team",
          content:
            "An expert team is basically a group of highly qualified professionals who have tremendous thorough knowledge within their fields as well as experience. They work together to produce high-quality solutions, offering specialized insights and creative approaches to complex challenges.",
        },
        {
          title: "Cost Effective Development",
          content:
            " Cost-effective development focuses on maximizing value while minimizing costs. It involves efficient resource allocation, adopting scalable technologies, & leveraging agile methodologies. Prioritizing essential features, reusing components, & optimizing workflows ensure high-quality results without overspending, fostering sustainable growth and innovation.",
        },
      ],
  },
  // Add more categories as needed
];

export default chooseData;
