import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import RantelSwiperSlider from "./ApplicationSwiperSlider/RantelSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

const RentalApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">

          <div className="container h-100">
            <div className="row h-100">

              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Rental Apps
                  </h1>
                  <h3 className="heading-tagline">
                    Rental apps simplify the process of finding and leasing properties, vehicles, equipment, and more. Users can browse listings, schedule viewings, and manage transactions efficiently, enhancing the rental experience.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className='Key-feature-heading'>We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As rental app specialists, we redefine the way individuals navigate the housing market. Our platform offers a user-friendly interface, enabling seamless property searches and transactions. With advanced filtering options and detailed property listings, finding your ideal rental has never been easier. From apartments to vacation homes, we cater to diverse needs and preferences. Landlords also benefit from our streamlined process, gaining access to a vast pool of potential tenants and efficient management tools. Whether you're searching for a new home or looking to list your property, our specialized rental app ensures a hassle-free experience for all parties involved. Experience convenience and efficiency with us today.
              </p>



              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">
              <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
            </div>
          </div>
        </section>

        <RantelSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <OurStepByStep/>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
}

export default RentalApps
