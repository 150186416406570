import React from "react";
import "./DynamicWebsite.css";
import SimilarFile from "../SimilarFile";
import { AiOutlineGlobal } from "react-icons/ai";
import { RiCustomerServiceFill } from "react-icons/ri";
import { SiSpringsecurity } from "react-icons/si";
import { FcManager } from "react-icons/fc";
import { Link } from "react-router-dom";
import SeoHelmet from "../../Helmet";
import FAQSection2 from "../FAQSection2";
import projectData from "../projectData";
import OurProjects from "../OurProjects";

const DynamicWebsite = () => {
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="MetaBlok Technologies- Dynamic Website Development Services in India"
          description="Our dynamic website development services in India at MetaBlock Technologies offer tailored, responsive, and SEO-friendly websites. We also specialize in dynamic web applications to increase features.
"
keywords="Dynamic Website Development Services"
        />
        &lt;&gt;
        <header className="ondemad_head">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <h1
                className="mb-5 mt-40  Dynamic-heading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Dynamic Website Development Services
              </h1>
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto E-commerce-header-paragraph">
                  <p className="text-left heading-tagline ">
                    Reach out to millions of users with your business worldwide
                    with the dynamic website. We design and develop a
                    feature-rich platform that fits your needs by providing you
                    with expertise in dynamic website development in Jaipur.
                    This combination goes hand in hand with the on-demand
                    application development providing ample customization tools
                    and elegant designs. Our team, driven towards delivering
                    solutions to provide exactly the same amount of quality, is
                    there to ensure your business is well different from others
                    in the vast expanse of the online world.
                  </p>
                  <ul className="banr-listing">
                    <li>
                      <i className="fa fa-check-circle" /> Meticulous quality
                      analysis cycle.
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Apps built on
                      leading-edge software packages.
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Incorporation of
                      authentic API tools.
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Exclusive Offshore
                      project development team.
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> On-time delivery at
                      an affordable cost.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="mid_section ondemad_feature embraced-features">
          <div className="container">
            <div className="section-heading text-center d-flex justify-center align-center flex-column grident-color-sub-title">
              <h2 style={{ fontSize: "45px" }} className="features_title">
                Features We Inherit
              </h2>
              <p className="text-center">
                We create on-demand dynamic websites with many features,
                including tracking, payment options, and more.
              </p>
              <div className="why_round"> </div>
            </div>
            <div className="row">
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box1 hgt_400">
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./images/map-setting.webp"
                          alt="GPS Services"
                          title="GPS Services"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <h3>Customized Design</h3>
                  <p className="text-center">
                    A customized design reflects the identity of your brand as a
                    product, tailored to your business needs and audience
                    preferences. It ensures a unique, engaging, and professional
                    user experience.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box2 hgt_400">
                  <h3>Content Management System</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./images/digital-wallet.webp"
                          alt="In - App Payments"
                          title="In - App Payments"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    A CMS gives businesses the power to easily manage and update
                    website content without technical know-how. It provides
                    flexibility, real-time updates, and scalability, making it
                    ideal for dynamic websites.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box3 hgt_400">
                  <h3>SEO Optimization</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="/SGimage/integrated-system.webp"
                          alt="Cloud Integration"
                          title="Cloud Integration"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    SEO optimization improves your website's presence in search
                    engines and increases organic traffic. It encompasses
                    content, meta tags, and site structure optimization to
                    increase rankings and user engagement.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box4 hgt_400">
                  <h3>Secure Infrastructure</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./SGimage/analytics.webp"
                          alt="Instant Push Notification"
                          title="Instant Push Notification"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    Securing Infrastructure Website and user information will be
                    well secured by the proper implementation of secure SSL
                    encryption, safe coding techniques, and performing constant
                    assessments on vulnerability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mid_section why-business grey-bg why-should-develop">
          <div className="container">
            <div className="section-heading text-center d-flex justify-center align-center flex-column grident-color-sub-title">
              <h2 style={{ fontSize: "35px" }} className="why_title">
                Why Should You develop an On-Demand Delivery App for Your
                Business?
              </h2>
              <div className="why_round"> </div>
            </div>
            <div className="row mar-top-30">
              <div className="col-md-12 col-sm-12">
                <p className="why_para text-center">
                  In today's digital world, on-demand applications and dynamic
                  e-commerce websites have become highly popular making things
                  easier and more convenient for consumers. Both proved to be
                  major successes within the tech industry. For entrepreneurs
                  looking to grow their business online or start from scratch,
                  leveraging the right on-demand app development services or
                  dynamic e-commerce website solutions is the ideal starting
                  point. These platforms enable fast, personalized experiences
                  and significant growth opportunities, helping businesses reach
                  a broader audience effectively.
                </p>
                <div className="row mar-top-30">
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <AiOutlineGlobal />
                      </abbr>
                      <h3>Global Reach</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <RiCustomerServiceFill />
                      </abbr>
                      <h3>Customer Comfort</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <SiSpringsecurity />
                      </abbr>

                      <h3>Security &amp; Scalability</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="whybottom_box whybottom_box1 hgt_400">
                      <abbr>
                        <FcManager />
                      </abbr>
                      <h3>Advanced Opportunities </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-heading mid_section-1 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 grident-color-sub-title">
                <h2
                  style={{ color: "#D166AF ", fontSize: "40px" }}
                  className="text-center Technology-Stack"
                >
                  Technology Stack We Use
                </h2>
              </div>
            </div>
            <div className="row tech p-5 pt-0 mt-5 mb-5">
              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/physics.webp"
                  className="w-100 h-40"
                  alt="React"
                />
                <h5>React</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5>Python</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mysql.webp"
                  alt="MySQL"
                  className="w-100 h-40"
                />
                <h5>MySQL</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/java-script.webp"
                  alt="Java Srcipt"
                  className="w-100 h-40"
                />
                <h5>Java Srcipt</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/SQL-Lite.webp"
                  alt="SQL Lite"
                  className="w-100 h-40"
                />
                <h5>SQL Lite</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/html.webp"
                  alt="HTML"
                  className="w-100 h-40"
                />
                <h5>HTML</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/android.webp"
                  alt="Android"
                  className="w-100 h-40"
                />
                <h5>Android</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mongo-db-icon.webp"
                  alt="Mongo DB"
                  className="w-100 h-40"
                />
                <h5>Mongo DB</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/app-store.webp"
                  alt="App Store"
                  className="w-100 h-40"
                />
                <h5>App Store</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/atom.webp"
                  alt="Atom"
                  className="w-100 h-40"
                />
                <h5>Atom</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/laravel.webp"
                  alt="Laravel"
                  className="w-100 h-40"
                />
                <h5>Laravel</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5>Python</h5>
              </div>
            </div>
          </div>
        </section>
        <section className="why-MetaBlock-hyp">
          <div className="container">
            <div className="text-center why_hyp_content grident-color-sub-title mb-4">
              <h2 style={{ fontSize: "30px" }} className="hyp_common_title">
                Why Develop On-Demand Delivery Apps Using MetaBlock?
              </h2>
            </div>
            <div className="row mar-top-30">
              <div className="col-md-6 col-sm-6">
                <div className="list_item">
                  <ul className="list-unstyled">
                    <li>
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="arrow_static"
                        className="hyp-tic-sprite"
                      />
                      Customizable On-Demand App Solutions.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Professionals"
                        className="hyp-tic-sprite"
                      />
                      Professionals backed by 10+ years of industry experience.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Direct collaboration with the technical team"
                        className="hyp-tic-sprite"
                      />
                      Direct collaboration with the technical team.{" "}
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Meticulous quality analysis cycle"
                        className="hyp-tic-sprite"
                      />
                      Meticulous quality analysis cycle.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 ">
                <div className="list_item">
                  <ul className="list-unstyled">
                    <li>
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Apps built on leading-edge software packages"
                        className="hyp-tic-sprite"
                      />
                      Apps built on leading-edge software packages.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Incorporation of authentic API tools"
                        className="hyp-tic-sprite"
                      />
                      Incorporation of authentic API tools.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Exclusive Offshore project development team"
                        className="hyp-tic-sprite"
                      />
                      Exclusive Offshore project development team.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="On-time delivery at an affordable cost"
                        className="hyp-tic-sprite"
                      />
                      On-time delivery at an affordable cost.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" eth-dapp-hire">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 eth-dap-hire-box">
                <div className="grident-color-sub-title pb-4">
                  <h2
                    style={{ fontSize: "35px" }}
                    className="ethdapp_common_title white-text"
                  >
                    Hire Our Dynamic Website Developers
                  </h2>
                </div>
                <p className="hire-para white-text ">
                  Hiring the skilled team of MetaBlock for dynamic web
                  development ensures that you get expert services for your
                  projects. We work in dedicated spaces by our developers,
                  ensuring total concentration on each task to meet high
                  standards. With constant and rapid implementation of client
                  feedback, we ensure that the final product fits the
                  expectations and delivers outstanding results.
                </p>
                <div className="mob-text-center">
                  <Link to="/contact-us">
                    {" "}
                    <span target="_blank" className="new_common_btn">
                      Hire Our Professional
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
        <OurProjects
            projectData={projectData}
            heading="Browse our impactful portfolio showcase"
            description=" Our services encompasses web development,app development,game development,software development and blockchain development."
            filterKey="dynamic" // Display only software projects
          />
          <SimilarFile />
        </div>
        <FAQSection2 category="dynamic"/>
      </div>
    </>
  );
};

export default DynamicWebsite;
