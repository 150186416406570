import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import ClinicSwiperSlider from "./SwiperSlider/ClinicSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const ClinicManagmentSystemSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Clinic Managment System Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Clinic Management System Software simplifies healthcare
                    administration by digitizing patient records, appointments,
                    and billing processes. With intuitive interfaces and secure
                    data storage, it improves clinic efficiency, enhances
                    patient care, and ensures compliance with regulatory
                    standards, empowering healthcare providers to focus on
                    delivering quality medical services.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in clinic management system software, our focus
                is on providing tailored solutions to meet the unique needs of
                healthcare providers and medical facilities. Our software is
                meticulously designed to streamline various aspects of clinic
                operations, including patient scheduling, electronic health
                records management, billing, and inventory tracking. With our
                expertise, clinics can efficiently manage their resources,
                improve patient care processes, and enhance overall efficiency.
                Whether it's a small private practice or a large medical center,
                our management system software is customizable to fit the
                specific requirements of each clinic, ensuring smooth operations
                and improved patient outcomes. We are committed to empowering
                healthcare providers with the tools they need to deliver
                high-quality care and streamline administrative tasks
                effectively.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/clientdev.webp" alt="clientdev" />
            </div>
          </div>
        </section>

        <ClinicSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <OurStepByStep/>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
      <CardSlider/>
    </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
      <Phonecardslider />
    </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default ClinicManagmentSystemSoftware;
