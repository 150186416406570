import React from "react";
import industriesData from "./industriesData"; // Adjust the path as necessary
import "./SoftwareUsesTechnology.css"; // Create a CSS file for styling if needed

const Industries = ({ category, heading }) => {
  // Filtered data based on the category prop
  const industries = industriesData[category] || [];

  return (
    <div className="m-0">
      <div className="dashboard-main-box">
        <h2
          className="Key-feature-heading mb-5"
          style={{ fontSize: "40px", textAlign: "center" }}
        >
          {heading} {/* Dynamic heading */}
        </h2>
        <div className="dashboard-container">
          {industries.map((industry) => (
            <div key={industry.id} className="dashboard-container-box">
              <div className="key-feature-heading">
                <img src={industry.image} alt={industry.title} />
                <h3 className="software-feature-headings d-flex flex-column justify-center align-center">
                  {industry.title}
                </h3>
                <p>{industry.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Industries;
