import React from "react";
import BenefitsColabratingSlider from "./MainPage/BenefitsOfColabratingSlider";

const BenefitsToCollaborating = () => {
  return (
    <div className="why_MetaBlock_blockchain_sec">
      <div className="container">
        <div className="BnrCnt OurservHdd mb-lg-4">
          <h2
            style={{ fontSize: "40px", fontWeight: "bold" }}
            className="text-center why-Metablock-heading grident-color-sub-title"
          >
            BENEFITS TO COLLABORATING WITH US
          </h2>
          <p className="text-center">
            At MetaBlock, customers are like a part of the family, and we
            treat every customer just like family and always put their
            needs before ours.
          </p>
        </div>

        {/* BenefitsColabratingSlider */}
        <div className="why_MetaBlock_blockchain_card">
          <div className="row align-items-center">
            <BenefitsColabratingSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsToCollaborating;
