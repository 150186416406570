import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import InventroySwiperSlider from "./SwiperSlider/InventroySwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const InventoryManagmentSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Inventory Managment Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Inventory Management Software optimizes stock control and
                    tracking, ensuring businesses maintain optimal inventory
                    levels while minimizing costs. With real-time monitoring and
                    analytics, it enhances supply chain efficiency and accuracy,
                    enabling organizations to meet customer demands promptly and
                    enhance overall operational performance.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in inventory management software, our expertise
                revolves around delivering tailored solutions to address the
                unique needs of businesses managing their stock and supplies.
                Our software is meticulously crafted to optimize inventory
                tracking, streamline procurement processes, and minimize
                stockouts or overstock situations. With our solution, businesses
                can efficiently monitor inventory levels, track item movements,
                and generate insightful reports for informed decision-making.
                Whether it's a small retail operation or a large-scale
                manufacturing facility, our inventory management software is
                designed to maximize efficiency, reduce costs, and improve
                overall operational performance.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/managmentsoft.webp" alt="managmentsoft" />
            </div>
          </div>
        </section>

        <InventroySwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
        <CardSlider/>
      </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
        <Phonecardslider />
      </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default InventoryManagmentSoftware;
