import React, { useState } from "react";
import faqData from "./faqData"; // Import the faqData file
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons for the toggle

const FAQSection2 = ({ category }) => {
  const faqList = faqData[category] || []; // Get the FAQ data for the category passed as prop
  const [expanded, setExpanded] = useState(null); // State to manage the expanded question

  const toggleExpanded = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="container mx-auto py-8 px-4 flex items-center justify-center min-h-screen">
      {/* FAQ and Image Container */}
      <div className="flex flex-col lg:flex-row justify-between items-center gap-12">
        {/* FAQ Section */}
        <div className="flex-1">
          <h2 className="text-7xl font-bold text-left mb-2">Frequently Asked</h2>
          <h3 className="text-6xl font-semibold text-left mb-6">Questions (FAQs)</h3>
          <div className="space-y-6">
            {faqList.map((faq, index) => (
              <div key={index} className="py-4">
                {/* Question */}
                <div
                  className="flex justify-between items-center bg-gradient-to-r from-blue-600 to-blue-400 text-white p-6 rounded-xl shadow-lg cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => toggleExpanded(index)}
                >
                  <h3 className="text-2xl font-semibold">{faq.question}</h3>
                  <button className="text-white transition-transform duration-300">
                    {expanded === index ? (
                      <FaChevronUp size={28} />
                    ) : (
                      <FaChevronDown size={28} />
                    )}
                  </button>
                </div>

                {/* Answer */}
                <div
                  className={`overflow-hidden transition-all duration-500 ease-in-out ${
                    expanded === index ? "max-h-[300px] opacity-100" : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="mt-2 text-white p-4 font-bold text-xl">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Image Section */}
        <div className="flex-1">
          <img
            src="./images/faqimage.webp"
            alt="FAQ related"
            className="w-full h-auto object-cover rounded-lg shadow-md"
          />
        </div>
      </div>
    </div>
  );
};

export default FAQSection2;
