// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import JoditEditor from 'jodit-react';

// const BlogArticle = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [category, setCategory] = useState('');
//   const [image, setImage] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const [editing, setEditing] = useState(false);
//   const [currentBlogId, setCurrentBlogId] = useState(null);
//   const [filteredBlogs, setFilteredBlogs] = useState([]);
//   const [expandedBlogId, setExpandedBlogId] = useState(null);
//   const [blogsToShow, setBlogsToShow] = useState(3); // Number of blogs to show initially and increment by
//   const editor = useRef(null);

//   useEffect(() => {
//     fetchBlogs();
//     fetchCategories();
//   }, []);

//   const fetchBlogs = async () => {
//     try {
//       const response = await axios.get('https://maincompanybackend.metablocktechnologies.org//api/v1/artical');
//       setBlogs(response.data);
//       setFilteredBlogs(response.data);
//     } catch (error) {
//       console.error('Error fetching blogs', error);
//     }
//   };

//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('https://maincompanybackend.metablocktechnologies.org//api/v1/blogcategory');
//       setCategories(response.data);
//     } catch (error) {
//       console.error('Error fetching categories', error);
//     }
//   };

//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('description', description);
//     formData.append('category_id', category);
//     formData.append('image', image);

//     try {
//       if (editing) {
//         await axios.put(`https://maincompanybackend.metablocktechnologies.org//api/v1/artical/update/${currentBlogId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         alert('Blog updated successfully');
//       } else {
//         const response = await axios.post('https://maincompanybackend.metablocktechnologies.org//api/v1/artical/create', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBlogs([response.data, ...blogs]);
//         alert('Blog created successfully');
//       }

//       setTitle('');
//       setDescription('');
//       setCategory('');
//       setImage(null);
//       setEditing(false);
//       setCurrentBlogId(null);
//       fetchBlogs(); // Refresh the list
//     } catch (error) {
//       console.error('Error creating or updating blog', error);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://maincompanybackend.metablocktechnologies.org//api/v1/artical/delete/${id}`);
//       setBlogs(blogs.filter((blog) => blog._id !== id));
//       setFilteredBlogs(filteredBlogs.filter((blog) => blog._id !== id));
//       alert('Blog deleted successfully');
//     } catch (error) {
//       console.error('Error deleting blog', error);
//     }
//   };

//   const handleEdit = (blog) => {
//     setTitle(blog.title);
//     setDescription(blog.description);
//     setCategory(blog.category_id._id);
//     setEditing(true);
//     setCurrentBlogId(blog._id);
//   };

//   const handleFilter = (categoryId) => {
//     if (categoryId === '') {
//       setFilteredBlogs(blogs);
//     } else {
//       const filtered = blogs.filter((blog) => blog.category_id._id === categoryId);
//       setFilteredBlogs(filtered);
//     }
//     setBlogsToShow(6); // Reset blogs to show when filtering
//   };

//   const truncateDescription = (description, maxLength) => {
//     if (description.length <= maxLength) return description;
//     return `${description.substring(0, maxLength)}...`;
//   };

//   const handleExpand = (blogId) => {
//     setExpandedBlogId((prevExpandedBlogId) => (prevExpandedBlogId === blogId ? null : blogId));
//   };

//   const handleLoadMore = () => {
//     setBlogsToShow(blogsToShow + 3);
//   };

//   const config = {
//     readonly: false,
//     height: 400,
//   };

//   console.log(categories);

//   return (
//     <div className="container mx-auto py-8">
//       <h1 className="text-3xl font-bold mb-6">Blog Management</h1>
//       <form onSubmit={handleSubmit} className="mb-6">
//         <div className="mb-4">
//           <label htmlFor="title" className="block text-sm font-medium text-gray-700">
//             Title
//           </label>
//           <input
//             type="text"
//             id="title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label htmlFor="description" className="block text-sm font-medium text-gray-700">
//             Description
//           </label>
//           <JoditEditor
//               ref={editor}
//               value={description}
//               onBlur={newDescription => setDescription(newDescription)}
//               onChange={newDescription => { }}
//               config={{
//                 readonly: false,
//                 toolbarSticky: false,
//                 iframe: true,
//                 iframeCSSLinks: [],
//                 iframeStyle: ``,
//               }}
//             />

//         </div>
//         <div className="mb-4">
//           <label htmlFor="category" className="block text-sm font-medium text-gray-700">
//             Category
//           </label>
//           <select
//             id="category"
//             value={category}
//             onChange={(e) => setCategory(e.target.value)}
//             className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
//             required
//           >
//             <option value="" className='text-black'>Select a category</option>
//             {categories.map((category) => (
//               <option key={category._id} value={category._id} className='text-black'>
//                 {category.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="mb-4">
//           <label htmlFor="image" className="block text-sm font-medium text-gray-700">
//             Image
//           </label>
//           <input
//             type="file"
//             id="image"
//             onChange={handleImageChange}
//             className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
//           />
//         </div>
//         <button
//           type="submit"
//           className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600"
//         >
//           {editing ? 'Update Blog' : 'Create Blog'}
//         </button>
//       </form>

//       <div className="mb-4">
//         <label htmlFor="filter" className="block text-sm font-medium text-gray-700">
//           Filter by Category
//         </label>
//         <select
//           id="filter"
//           onChange={(e) => handleFilter(e.target.value)}
//           className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
//         >
//           <option value="" className='text-black'>All Categories</option>
//           {categories?.map((category) => (
//             <option key={category._id} value={category._id} className='text-black'>
//               {category.name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         {filteredBlogs.slice(0, blogsToShow).map((blog) => (
//           <div key={blog._id} className="bg-white shadow-md rounded-lg p-4">
//             <img src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog?.image}`} alt={blog.title} className="w-full h-40 object-cover rounded-md mb-4" />
//             <h2 className="text-lg font-bold mb-2">{blog.title}</h2>
//             <p className=" mb-4 text-black">
//               {expandedBlogId === blog._id
//                 ? blog.description
//                 : truncateDescription(blog.description, 100)}
//               {blog.description.length > 100 && (
//                 <button
//                   onClick={() => handleExpand(blog._id)}
//                   className="text-blue-500 hover:underline"
//                 >
//                   {expandedBlogId === blog._id ? 'Show Less' : 'Read More'}
//                 </button>
//               )}
//             </p>
//             <div className="flex justify-between items-center">
//               <button
//                 onClick={() => handleEdit(blog)}
//                 className="bg-green-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-600"
//               >
//                 Edit
//               </button>
//               <button
//                 onClick={() => handleDelete(blog._id)}
//                 className="bg-red-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-red-600"
//               >
//                 Delete
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>

//       {blogsToShow < filteredBlogs.length && (
//         <div className="flex justify-center mt-6">
//           <button
//             onClick={handleLoadMore}
//             className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-600"
//           >
//             Load More
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default BlogArticle;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";

const BlogArticle = () => {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentBlogId, setCurrentBlogId] = useState(null);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [expandedBlogId, setExpandedBlogId] = useState(null);
  const [blogsToShow, setBlogsToShow] = useState(3);
  const editor = useRef(null);

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org//api/v1/artical"
      );
      setBlogs(response.data);
      setFilteredBlogs(response.data);
    } catch (error) {
      console.error("Error fetching blogs", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org//api/v1/blogcategory"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('title', title);
  //   formData.append('description', description);
  //   formData.append('category_id', category);
  //   formData.append('image', image);

  //   try {
  //     if (editing) {
  //       await axios.put(`https://maincompanybackend.metablocktechnologies.org//api/v1/artical/update/${currentBlogId}`, formData, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       });
  //       alert('Blog updated successfully');
  //     } else {
  //       const response = await axios.post('https://maincompanybackend.metablocktechnologies.org//api/v1/artical/create', formData, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       });
  //       setBlogs([response.data, ...blogs]);
  //       alert('Blog created successfully');
  //     }

  //     setTitle('');
  //     setDescription('');
  //     setCategory('');
  //     setImage(null);
  //     setEditing(false);
  //     setCurrentBlogId(null);
  //     fetchBlogs(); // Refresh the list
  //   } catch (error) {
  //     console.error('Error creating or updating blog', error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category_id", category);
    formData.append("image", image);

    try {
      let response;
      if (editing) {
        response = await axios.put(
          `https://maincompanybackend.metablocktechnologies.org//api/v1/artical/update/${currentBlogId}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        response = await axios.post(
          "https://maincompanybackend.metablocktechnologies.org//api/v1/artical/create",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }
      console.log(response.data); // Log response data for debugging
      alert("Blog created or updated successfully");
    } catch (error) {
      console.error("Error creating or updating blog", error.response.data); // Log error response
    }

    setTitle("");
    setDescription("");
    setCategory("");
    setImage(null);
    setEditing(false);
    setCurrentBlogId(null);
    fetchBlogs(); // Refresh the list
  };

  // const handleDelete = async (id) => {
  //   try {
  //     await axios.delete(`https://maincompanybackend.metablocktechnologies.org//api/v1/artical/delete/${id}`);
  //     setBlogs(blogs.filter((blog) => blog._id !== id));
  //     setFilteredBlogs(filteredBlogs.filter((blog) => blog._id !== id));
  //     alert('Blog deleted successfully');
  //   } catch (error) {
  //     console.error('Error deleting blog', error);
  //   }
  // };

  const handleDelete = async (id) => {
    try {
      // Send DELETE request to the API to delete the blog post
      await axios.delete(
        `https://maincompanybackend.metablocktechnologies.org//api/v1/artical/delete/${id}`
      );

      // Remove the deleted blog post from the state
      setBlogs(blogs.filter((blog) => blog._id !== id));
      setFilteredBlogs(filteredBlogs.filter((blog) => blog._id !== id));

      // Notify user of success
      alert("Blog deleted successfully");
    } catch (error) {
      console.error("Error deleting blog", error);
    }
  };

  const handleEdit = (blog) => {
    setTitle(blog.title);
    setDescription(blog.description);
    setCategory(blog.category_id._id);
    setEditing(true);
    setCurrentBlogId(blog._id);
  };

  const handleFilter = (categoryId) => {
    if (categoryId === "") {
      setFilteredBlogs(blogs);
    } else {
      const filtered = blogs.filter(
        (blog) => blog.category_id._id === categoryId
      );
      setFilteredBlogs(filtered);
    }
    setBlogsToShow(6); // Reset blogs to show when filtering
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) return description;
    return `${description.substring(0, maxLength)}...`;
  };

  const handleExpand = (blogId) => {
    setExpandedBlogId((prevExpandedBlogId) =>
      prevExpandedBlogId === blogId ? null : blogId
    );
  };

  const handleLoadMore = () => {
    setBlogsToShow(blogsToShow + 3);
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold text-center mb-6 text-gradient bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
          Blog Management
        </h1>
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            {/* <JoditEditor
              ref={editor}
              value={description}
              onBlur={(newDescription) => setDescription(newDescription)}
              onChange={(newDescription) => {}}
              config={{
                readonly: false,
                toolbarSticky: false,
                iframe: true,
                iframeCSSLinks: [],
                iframeStyle: ``,
              }}
            /> */}

            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
              rows="10"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
              required
            >
              <option value="" className="text-black">
                Select a category
              </option>
              {categories.map((category) => (
                <option
                  key={category._id}
                  value={category._id}
                  className="text-black"
                >
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-700"
            >
              Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 w-full"
          >
            {editing ? "Update Blog" : "Create Blog"}
          </button>
        </form>
      </div>

      <div className="w-full mt-8 px-4">
        <div className="mb-4">
          <label
            htmlFor="filter"
            className="block text-sm font-medium text-gray-700"
          >
            Filter by Category
          </label>
          <select
            id="filter"
            onChange={(e) => handleFilter(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
          >
            <option value="" className="text-black">
              All Categories
            </option>
            {categories?.map((category) => (
              <option
                key={category._id}
                value={category._id}
                className="text-black"
              >
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredBlogs.slice(0, blogsToShow).map((blog) => (
            <div key={blog._id} className="bg-white shadow-md rounded-lg p-4">
              <img
                src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog?.image}`}
                alt={blog.title}
                className="w-full h-60 object-cover rounded-md mb-4"
              />
              <h2 className="text-lg font-bold mb-2 text-black text-center">{blog.title}</h2>
              <p className="mb-4 text-black">
                {expandedBlogId === blog._id
                  ? blog.description
                  : truncateDescription(blog.description, 100)}
                {blog.description.length > 100 && (
                  <button
                    onClick={() => handleExpand(blog._id)}
                    className="text-blue-500 hover:underline"
                  >
                    {expandedBlogId === blog._id ? 'Show Less' : 'Read More'}
                  </button>
                )}
              </p>
              <div className="flex justify-between items-center">
                <button
                  onClick={() => handleEdit(blog)}
                  className="bg-green-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(blog._id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredBlogs.slice(0, blogsToShow).map((blog) => (
            <div key={blog._id} className="bg-white shadow-md rounded-lg p-4">
              <img
                src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog.image}`}
                alt={blog.title}
                className="w-full h-60 object-cover rounded-md mb-4"
              />
              <h2 className="text-lg font-bold mb-2 text-black text-center">
                {blog.title}
              </h2>
              <p className="mb-4 text-black">
                {expandedBlogId === blog._id
                  ? blog.description
                  : truncateDescription(blog.description, 100)}
                {blog.description.length > 100 && (
                  <button
                    onClick={() => handleExpand(blog._id)}
                    className="text-blue-500 hover:underline"
                  >
                    {expandedBlogId === blog._id ? "Show Less" : "Read More"}
                  </button>
                )}
              </p>
              <div className="flex justify-between items-center">
                <button
                  onClick={() => handleEdit(blog)}
                  className="bg-green-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(blog._id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>

        {blogsToShow < filteredBlogs.length && (
          <div className="flex justify-center mt-6">
            <button
              onClick={handleLoadMore}
              className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-600"
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogArticle;

// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';

// const BlogArticle = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [filteredBlogs, setFilteredBlogs] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [blogsToShow, setBlogsToShow] = useState(3);
//   const [expandedBlogId, setExpandedBlogId] = useState(null);

//   useEffect(() => {
//     fetchCategories();
//     fetchBlogs();
//   }, []);

//   // Fetch all blogs
//   const fetchBlogs = async () => {
//     try {
//       const response = await axios.get('https://maincompanybackend.metablocktechnologies.org//api/v1/artical');
//       setBlogs(response.data);
//       setFilteredBlogs(response.data);
//     } catch (error) {
//       console.error('Error fetching blogs', error);
//     }
//   };

//   // Fetch all categories
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('https://maincompanybackend.metablocktechnologies.org//api/v1/blogcategory');
//       setCategories(response.data);
//     } catch (error) {
//       console.error('Error fetching categories', error);
//     }
//   };

//   // Fetch blogs by category
//   const fetchBlogsByCategory = async (categoryId) => {
//     try {
//       if (categoryId === '') {
//         fetchBlogs(); // Fetch all blogs if no category is selected
//       } else {
//         const response = await axios.get(`https://maincompanybackend.metablocktechnologies.org//api/v1/artical/category/${categoryId}`);
//         setFilteredBlogs(response.data);
//       }
//     } catch (error) {
//       console.error('Error fetching blogs by category', error);
//     }
//   };

//   // Handle category filter
//   const handleFilter = (categoryId) => {
//     setSelectedCategory(categoryId);
//     fetchBlogsByCategory(categoryId);
//     setBlogsToShow(6); // Reset blogs to show when filtering
//   };

//   const truncateDescription = (description, maxLength) => {
//     if (description.length <= maxLength) return description;
//     return `${description.substring(0, maxLength)}...`;
//   };

//   const handleExpand = (blogId) => {
//     setExpandedBlogId((prevExpandedBlogId) => (prevExpandedBlogId === blogId ? null : blogId));
//   };

//   const handleLoadMore = () => {
//     setBlogsToShow(blogsToShow + 3);
//   };

//   return (
//     <div className="container mx-auto py-8">
//       <h1 className="text-3xl font-bold mb-6">Blog Management</h1>

//       <div className="mb-4">
//         <label htmlFor="filter" className="block text-sm font-medium text-gray-700">
//           Filter by Category
//         </label>
//         <select
//           id="filter"
//           value={selectedCategory}
//           onChange={(e) => handleFilter(e.target.value)}
//           className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
//         >
//           <option value="">All Categories</option>
//           {categories.map((category) => (
//             <option key={category._id} value={category._id}>
//               {category.name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         {filteredBlogs.slice(0, blogsToShow).map((blog) => (
//           <div key={blog._id} className="bg-white shadow-md rounded-lg p-4">
//             <img src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog.image}`} alt={blog.title} className="w-full h-40 object-cover rounded-md mb-4" />
//             <h2 className="text-lg font-bold mb-2">{blog.title}</h2>
//             <p className="mb-4 text-black">
//               {expandedBlogId === blog._id
//                 ? blog.description
//                 : truncateDescription(blog.description, 100)}
//               {blog.description.length > 100 && (
//                 <button
//                   onClick={() => handleExpand(blog._id)}
//                   className="text-blue-500 hover:underline"
//                 >
//                   {expandedBlogId === blog._id ? 'Show Less' : 'Read More'}
//                 </button>
//               )}
//             </p>
//           </div>
//         ))}
//       </div>

//       {blogsToShow < filteredBlogs.length && (
//         <div className="flex justify-center mt-6">
//           <button
//             onClick={handleLoadMore}
//             className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-600"
//           >
//             Load More
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default BlogArticle;
