import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";
import ChattingSwiperSlider from "./ApplicationSwiperSlider/ChattingSwiperSlider";
import SeoHelmet from "../../../Helmet";
import TechnologyStack from "../../../components/TechnologyStack";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

const ChattingApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Custom Chat App Development Company In India"
          description="Metablock Technologies is a prominent chat app development company with the top-rated chat app developers. It offers messaging app development services in low-cost. "
          keywords="Chat App Development Company"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Chat App Development Company| Instant Messaging App
                    </h1>
                    <p className="heading-tagline">
                      Chat app development company enables instant global
                      communication with text, voice, and video calls, along
                      with features like emojis, group chats, and media sharing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Chatting Apps specialist
                </h2>
                <p style={{ fontSize: "20px" }}>
                  Stay connected easily with our easy-to-use chat app
                  development solutions. It offers instant messaging, media
                  sharing, and customization options to suit your needs.
                  Designed for simplicity, our app makes chatting smooth and
                  enjoyable for everyone, whether for casual chats or
                  professional communication. Join now and experience the
                  convenience of our app!
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img
                  src="https://soften.themeht.com/wp-content/uploads/2023/12/05-1.png"
                  alt="We Are Specialist"
                />
              </div>
            </div>
          </section>

          <ChattingSwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>
          <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />

          <section>
            <SimilarFile />
          </section>
        </div>
        <FAQSection2 category="chatapp"/>
      </div>
    </>
  );
};

export default ChattingApps;
