import React from "react";
import "./whychoose.css";
import WhyChooseSlider from "./WhyChooseSlider";
const WhyChooseUs = () => {
  return (
    <section className="why-chhose-us-section cta position-relative  changing overflow-hidden">
      <img className="bg-img" src="./assets/img/blog-background-01.png" alt />
      <div className="bg-filter" />
      <div className="devanshu">
        <div className="col-lg-5 container c-contant-text  row gx-5   align-items-center">
          <div className="why-choose-us-right-slider">
            <h2
              style={{ fontSize: "40px" }}
              className="pb-3 glow-text c-head text-white fw-bold g-text g-text-h"
            >
              Why choose us in software development?
            </h2>
            <p className="lead fw-normal text-light mb-0 ">
              Partner with us for innovative software development, as we
              customize scalable solutions from scratch according to your needs.
              Through our expert team, quality work, seamless communication, and
              timely delivery ensure success and growth for you.
            </p>

            <img
              src="./images/Furore.webp"
              alt="Furore | metablocktechnologies"
            />
          </div>
        </div>

        <div className="col-lg-6">
          <WhyChooseSlider />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;