import React from 'react';

const testimonialsData = [
    {
        name: "Aarav Mehta",
        role: "Project Manager",
        avatar: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png",
        rating: 5,
        quote: "Metablock Technologies made our project simple and stress-free. Their team delivered excellent results on time.",
    },
    {
        name: "Ananya Sharma",
        role: "Game Studio Owner",
        avatar: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png",
        rating: 5,
        quote: "The game they developed for us was amazing. Great visuals, smooth gameplay, and they understood our vision perfectly.",
    },
    {
        name: "Rohan Gupta",
        role: "Tech Startup CTO",
        avatar: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
        rating: 4,
        quote: "Their website design was fantastic! There was a slight delay, but the final product was exactly what we needed.",
    },
    {
        name: "Ishita Patel",
        role: "Gaming Enthusiast",
        avatar: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
        rating: 5,
        quote: "The game they created is top-notch! It’s fun, looks great, and works perfectly. I’m very impressed with their work.",
    },
    {
        name: "sunil Nair",
        role: "Business Owner",
        avatar: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
        rating: 5,
        quote: "They developed a custom app for my business, and it’s exactly what I wanted. The team is professional and talented.",
    },
];



const Testimonials = () => {
    return (
        <section style={{ padding: '3rem 0', backgroundColor: '#05000D', color: '#e2e8f0', marginTop: "150px" }}>
        <div style={{ margin: '0 auto', maxWidth: '1200px', padding: '0 1rem' }}>
            <div style={{ textAlign: 'center' }}>
                <p style={{ fontSize: '1.125rem', fontWeight: '500', color: '#a0aec0' }}>
                   people have said how good Metablock
                </p>
                <h2
                    style={{
                        marginTop: '1rem',
                        fontSize: '2rem',
                        fontWeight: '700',
                        color: '#e2e8f0',
                        textAlign: 'center',
                    }}
                >
                    Our happy clients say about us
                </h2>
            </div>
    
            <div style={{ marginTop: '2.5rem', position: 'relative' }}>
                <div
                    style={{
                        display: 'grid',
                        gap: '1.5rem',
                        margin: '0 auto',
                        maxWidth: '1024px',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                    }}
                >
                    {testimonialsData.map((testimonial, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#2d3748',
                                borderRadius: '0.5rem',
                            }}
                        >
                            <div style={{ padding: '1.5rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
    {[...Array(testimonial.rating)].map((_, idx) => (
        <img
            key={idx}
            src="/images/star.png" // Replace with your image path
            alt="star"
            style={{
                width: '20px',
                height: '20px',
                marginRight: '4px',
            }}
        />
    ))}
</div>

                                <blockquote>
                                    <p style={{ fontSize: '1rem', lineHeight: '1.75rem', color: '#e2e8f0' }}>
                                        {testimonial.quote}
                                    </p>
                                </blockquote>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                                    <img
                                        style={{
                                            width: '44px',
                                            height: '44px',
                                            objectFit: 'cover',
                                            borderRadius: '9999px',
                                        }}
                                        src={testimonial.avatar}
                                        alt={testimonial.name}
                                    />
                                    <div style={{ marginLeft: '1rem' }}>
                                        <p style={{ fontSize: '1rem', fontWeight: '700', color: '#e2e8f0' }}>
                                            {testimonial.name}
                                        </p>
                                        <p style={{ marginTop: '0.25rem', fontSize: '0.875rem', color: '#a0aec0' }}>
                                            {testimonial.role}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </section>
    
    
    

    );
};

export default Testimonials;
