import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";



import { useNavigate } from "react-router-dom";

import EmployeeSwiperSlider from "./SwiperSlider/EmployeeSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const EmployeeTrackingSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Employee Tracking Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Employee Tracking Software enables businesses to monitor
                    employee activities and productivity levels efficiently.
                    With its comprehensive features and analytics, it enhances
                    workforce management, fosters accountability, and promotes
                    optimal performance across various organizational tasks and
                    projects.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in employee tracking software, our focus is on
                delivering effective solutions to streamline workforce
                management and enhance productivity. Our software is
                meticulously designed to track employee attendance, monitor work
                hours, and facilitate performance evaluation. With our
                expertise, businesses can efficiently manage their workforce,
                ensure compliance with labor regulations, and optimize
                scheduling processes. Whether it's tracking remote workers or
                monitoring on-site staff, our employee tracking software
                provides real-time insights into employee activities, enabling
                businesses to make data-driven decisions and improve overall
                operational efficiency.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/trakingdeva.webp" alt="trakingdeva" />
            </div>
          </div>
        </section>

        <EmployeeSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <OurStepByStep/>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
      <CardSlider/>
    </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
      <Phonecardslider />
    </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default EmployeeTrackingSoftware;
