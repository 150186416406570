import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import SocialMediaSwiperSlider from "./ApplicationSwiperSlider/SocialMediaSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

// import '../../main.css'

const SocialMediaApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">

          <div className="container h-100">
            <div className="row h-100">

              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Social Media Apps                  </h1>
                  <h3 className="heading-tagline">

                    Social media apps revolutionize communication and connection, offering platforms for sharing content, engaging with friends, and networking. With features like messaging, news feeds, and multimedia sharing, they shape modern social interactions.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className='Key-feature-heading'>We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As pioneers in social media apps, we've crafted a platform that transcends mere connection to foster genuine engagement and community. Our app prioritizes user experience, offering intuitive interfaces and innovative features tailored for seamless interaction. From customizable profiles to curated content feeds, we empower users to curate their digital identities and explore diverse interests. Real-time messaging and interactive features facilitate meaningful conversations and collaborations, fostering deeper connections in the digital realm. Whether sharing moments with friends or discovering new passions, our specialized app redefines social networking as a dynamic and enriching experience. Join us and embark on a journey of authentic connection and discovery today.              </p>



              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">
              <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
            </div>
          </div>
        </section>

        <SocialMediaSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <OurStepByStep/>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
}

export default SocialMediaApps
