import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import AstrologiesSwiperSlider from "./ApplicationSwiperSlider/AstrologiesSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
import OurStepByStep from "../../../components/OurStepByStep";

const AstrologyApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">Astrology Apps</h1>
                  <h3 className="heading-tagline">
                    Astrology apps offer personalized horoscopes, daily
                    insights, and compatibility readings, making it easy for
                    users to explore their astrological profiles. With intuitive
                    interfaces and live consultations, they provide cosmic
                    guidance and insights into life's mysteries.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                One of the top astrology app development companies in India is
                MetaBlock Technology, which enables you connect with astrologers
                and those looking for astrological insights by offering
                specialized app development solutions.Customers can ask
                astrologers questions about their love lives and communicate
                with them. Additionally, we may include a section in which
                astrologers could write summaries of various zodiac signs and
                their romantic preferences.
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">
              <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
            </div>
          </div>
        </section>

        <AstrologiesSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <OurStepByStep/>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
        </section>
        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default AstrologyApps;
