import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";
import AstrologySwiperSlider from "./SwiperSlider/AstrologySwiperSlider";
import SeoHelmet from "../../../Helmet";

import FAQSection2 from "../../FAQSection2";
import TechnologyStack from "../../../components/TechnologyStack";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const AstrologySoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Best Astrology Software | Custom Tools for Personalized Prediction"
          description=" Explore creative best astrology software solutions that suit your needs. Our expert team makes friendly, nice astrology applications and platforms to empower astrologers or admirers worldwide. "
          keywords=" Best Astrology Software"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Unlocking the Stars: Developing the Best Astrology
                      Software for the Future
                    </h1>
                    <p className="heading-tagline">
                      Best Astrology software development primarily relates to
                      designing applications that interpret celestial movements
                      and influence the lives of human beings. They usually
                      involve complex algorithms for personalized birth charts,
                      planetary transit, and compatibility reports.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  {" "}
                 Specialists in the best astrology prediction software
                </h2>
                <p style={{ fontSize: "20px" }}>
                  We are the best in astrology software development, and we have
                  well-defined innovative, accurate, and user-friendly
                  solutions. Our expertise boasts of providing top-of-the-line
                  tools to astrologers and helping them give personalized
                  readings and predictions with improved features and high
                  precision.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/astolgydev.webp" alt="astolgydev" />
              </div>
            </div>
          </section>

          <AstrologySwiperSlider />
    
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
          <OurProjects
            projectData={projectData}
            heading="Browse our impactful portfolio showcase"
            description=" Our services encompasses web development,app development,game development,software development and blockchain development."
            filterKey="astrology" // Display only software projects
          />
            <SimilarFile />
          </section>
          <FAQSection2 category="astrology"/>
        </div>
      </div>
    </>
  );
};

export default AstrologySoftware;
