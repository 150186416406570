import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./ReactSlick.css";

import {
  Autoplay,
  Pagination,
  Navigation,
  Mousewheel,
  Keyboard,
} from "swiper/modules";

export default function WhyChooseSlider() {
  return (
    <>
      <Swiper
        cssMode={true}
        navigation={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiper"
        loop={true}
      >
        <SwiperSlide>
          <div className="col-lg-12">
            <div className="row gap-5 c-contant-1 d-flex justify-content-center">
              <div className="award-1 col-lg-4 p-0 px-1 pt-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  {/* <h1 className="fs-4 year">YEARS of Work Exp.</h1> */}
                  <h3 className="text-warning" style={{ fontSize: "1.5rem" }}>
                    In-House Development
                  </h3>
                  <p style={{ fontSize: "1.3rem" }} className="chooseMetablock">
                    In-house development refers to creating software or systems
                    using a Network’s internal team, ensuring full control over
                    processes, customization, & data security. It fosters
                    collaboration, & aligns with company goals, but may require
                    Important time, resources, & technical expertise.
                  </p>
                </div>
              </div>
              <div className="award-1 col-lg-4 p-0 px-1 pt-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.4rem" }}>
                    Using Latest Technologies
                  </h3>
                  <p style={{ fontSize: "1.3rem" }} className="chooseMetablock">
                    Adopting the latest technology empowers organizations to
                    raise productivity, optimize efficiency, and foster faster
                    innovation. By implementing modern tools, software, and
                    systems, businesses can fine-tune their processes, create
                    exceptional products, and keep ahead of their competitors
                  </p>
                </div>
              </div>
              <div className="award-1 col-lg-4 p-0 px-1 pt-1 award-same-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.4rem" }}>
                    Testing Facility
                  </h3>
                  <p style={{ fontSize: "1.3rem" }} className="chooseMetablock">
                    A testing facility is a specific environment designed for
                    the comprehensive evaluation of products, software, or
                    systems to ensure they meet quality and performance
                    standards. It supplies the essential resources and tools for
                    various testing types, such as functional, security, and
                    stress testing.
                  </p>
                </div>
              </div>
              <div className="award-1 col-lg-4 p-0 px-1 pt-1 award-same-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.4rem" }}>
                    After-Sale Support
                  </h3>
                  <p style={{ fontSize: "1.3rem" }} className="chooseMetablock">
                    After-sale support refers to the help offered to customers
                    after they buy a product or service. This support
                    encompasses troubleshooting, product installation,
                    maintenance, updates, and resolving any questions or
                    problems that may come up.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="col-lg-12">
            <div className="row gap-5 c-contant-1 d-flex justify-content-center">
              <div className="award-1 col-lg-4 p-0 px-1 pt-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.3rem" }}>
                    Post Payment Terms
                  </h3>
                  <p
                    style={{ fontSize: "1.3rem" }}
                    className="chooseMetablock4"
                  >
                    Post-payment terms refer to agreements where buyers pay for
                    goods or services after delivery or completion, typically
                    within a specified period, such as 30 or 60 days. These
                    terms foster trust, flexibility, and smoother cash flow
                    management for organizations.
                  </p>
                </div>
              </div>

              <div className="award-1 col-lg-4 p-0 px-1 pt-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.4rem" }}>
                    On-Time Project Delivery
                  </h3>
                  <p style={{ fontSize: "1.3rem" }} className="chooseMetablock">
                    On-time project delivery ensures tasks are completed within
                    the agreed timeframe, fostering trust and efficiency. It
                    enhances client satisfaction, minimizes costs, & maintains
                    project quality by adhering to deadlines through effective
                    planning, resource management, & clear communication.
                  </p>
                </div>
              </div>
              <div className="award-1 col-lg-4 p-0 px-1 pt-1 award-same-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.4rem" }}>
                    Expert Team and Religious Team
                  </h3>
                  <p style={{ fontSize: "1.3rem" }} className="chooseMetablock">
                    An expert team is basically a group of highly qualified
                    professionals who have tremendous thorough knowledge within
                    their fields as well as experience. They work together to
                    produce high-quality solutions, offering specialized
                    insights and creative approaches to complex challenges.
                  </p>
                </div>
              </div>
              <div className="award-1 col-lg-4 p-0 px-1 pt-1 award-same-1">
                <div className="text-start text-white p-award-1 rounded-10 p-3">
                  <h3 className="text-warning" style={{ fontSize: "1.4rem" }}>
                    Cost Effective Development
                  </h3>
                  <p style={{ fontSize: "1.2rem" }} className="chooseMetablock">
                    Cost-effective development focuses on maximizing value while
                    minimizing costs. It involves efficient resource allocation,
                    adopting scalable technologies, & leveraging agile
                    methodologies. Prioritizing essential features, reusing
                    components, & optimizing workflows ensure high-quality
                    results without overspending, fostering sustainable growth
                    and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
