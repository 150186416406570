import React from "react";
import "./Ecommerce.css";
import SimilarFile from "../SimilarFile";
import { AiOutlineGlobal } from "react-icons/ai";
import { RiCustomerServiceFill } from "react-icons/ri";
import { SiSpringsecurity } from "react-icons/si";
import { FcManager } from "react-icons/fc";
import SeoHelmet from "../../Helmet";
import FAQSection2 from "../FAQSection2";
import projectData from "../projectData";
import OurProjects from "../OurProjects";

const Ecommerce = () => {
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle=" Best E-Commerce Website Development Services in India
"
          description="Expert ecommerce website development services for B2B ecommerce, B2C ecommerce, and C2C e-commerce. Build secure, user-friendly platforms to boost sales and enhance customer experiences.
"
keywords="E-Commerce Website Development Services"
        />
        &lt;&gt;
        <header className="ondemad_head">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center ">
              <h1
                className="mb-5 mt-50 E-commerce-heading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                E-Commerce Website Development Services
              </h1>
              <div className="col-lg-12 my-auto mb-1">
                <div className="header-content E-commerce-header-paragraph">
                  {/*  <h1 class="mb-5 mobile" style="text-align: left;">On-Demand App Development Company </h1> */}
                  <p className="text-left heading-tagline">
                    An ecommerce website is actually a good platform through
                    which businesses can showcase products and services
                    seamlessly online. MetaBlock Technologies has expertise in
                    ecommerce website development and ecommerce website design.
                    We design user-friendly, secure, and mobile-responsive
                    ecommerce platforms. Our all-included ecommerce services
                    will thus allow businesses to extend their radius, increase
                    sales, and make their customer shopping experience an easy
                    one. As a leading ecommerce website development company in
                    Jaipur, we provide customized solutions to drive the success
                    of your venture from its online perspective.
                  </p>
                  <br />
                  <ul className="banr-listing">
                    <li>
                      <i className="fa fa-check-circle" /> Global Reach
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Cost-Effectiveness
                    </li>
                    <li>
                      <i className="fa fa-check-circle" />
                      High Security
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Easy Maintenance
                    </li>
                    <li>
                      <i className="fa fa-check-circle" /> Reliability and
                      Stability
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="mid_section ondemad_feature embraced-features">
          <div className="container">
            <div className="section-heading text-center d-flex justify-center align-center flex-column grident-color-sub-title">
              <h2 style={{ fontSize: "45px" }} className="features_title">
                Embraced Features
              </h2>
              <p className="text-center">
                We create e-commerce websites that offer a plethora of features,
                such as payment and tracking capabilities, among others.
              </p>
              <div className="why_round"> </div>
            </div>
            <div className="row">
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box1 hgt_400">
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./images/map-setting.webp"
                          alt="GPS Services"
                          title="GPS Services"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <h3>User-Friendly Design</h3>
                  <p className="text-center">
                    Make and mold a user-friendly eCommerce design to ensure a
                    customer can be easily navigated, quickly checked out, and
                    happiest of all.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box2 hgt_400">
                  <h3>Product Management System</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./images/digital-wallet.webp"
                          alt="GPS Services"
                          title="GPS Services"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    In eCommerce, it refers to a product management system that
                    helps simplify the entry, record keeping, and pricing of
                    products combined together for sales purposes.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box3 hgt_400">
                  <h3>Search and Filter Plans </h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="/SGimage/integrated-system.webp"
                          alt="Cloud Integration"
                          title="Cloud Integration"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    Find the right product quickly using efficient searching and
                    filtering at eCommerce platforms.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 mt-7">
                <div className="whybottom_box whybottom_box4 hgt_400">
                  <h3>Customer Support</h3>
                  <div className="inr_img">
                    <div className="ver_mid">
                      <div className="img_box">
                        <img
                          width={"50px"}
                          src="./SGimage/analytics.webp"
                          alt="Instant Push Notification"
                          title="Instant Push Notification"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    Good customer support in eCommerce is easily resolved within
                    a short time and thus makes the customers more satisfied and
                    loyal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mid_section why-business grey-bg why-should-develop">
          <div className="container">
            <div className="section-heading text-center d-flex justify-center align-center flex-column grident-color-sub-title">
              <h2 style={{ fontSize: "30px" }} className="why_title">
                Why Should You develop an Ecommerce Website for Your Business?
              </h2>
              <div className="why_round"> </div>
            </div>
            <div className="row mar-top-30">
              <div className="col-md-12 col-sm-12">
                <p className="why_para text-center">
                  An e-commerce website is very important for businesses
                  targeting success in the digital economy. This is possible
                  through the medium, reaching out to a global audience,
                  showcasing your products or services 24/7, and thereby
                  substantially boosting your sales potential. Through
                  collaboration with a professional web development company, you
                  will be able to have an easily accessible, pleasing to the
                  eye, and suitable-for-business-need platform that comes with
                  secure payment gateways, mobile responsiveness, and navigation
                  to provide a great experience to the customers.
                </p>
                <div className="row mar-top-30">
                  <div className="col-md-12 col-sm-12">
                    <p className="why_para text-center">
                      An e-commerce website also helps track customer behavior,
                      enabling data-driven decisions to optimize your offerings
                      and marketing strategies. Take your business online today
                      to stay competitive and maximize growth opportunities!
                    </p>
                    <div className="row mar-top-30">
                      <div className="col-sm-3">
                        <div className="whybottom_box whybottom_box1 hgt_400">
                          <abbr>
                            {/* <img
                        src="./SGimage/advertising.webp"
                        alt="Global Reach"
                        title="Global Reach"
                        style={{ objectPosition: "-7px -18px" }}
                      /> */}
                            <AiOutlineGlobal />
                          </abbr>
                          <h3>Customer Engagement</h3>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="whybottom_box whybottom_box1 hgt_400">
                          <abbr>
                            <RiCustomerServiceFill />
                          </abbr>
                          <h3>Flexibility and Accessibility</h3>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="whybottom_box whybottom_box1 hgt_400">
                          <abbr>
                            <SiSpringsecurity />
                          </abbr>
                          <h3>Competitive Advantage</h3>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="whybottom_box whybottom_box1 hgt_400">
                          <abbr>
                            <FcManager />
                          </abbr>
                          <h3>Marketing Opportunities</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-heading mid_section-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 grident-color-sub-title">
                <h2
                  style={{ color: "#D166AF ", fontSize: "40px" }}
                  className="text-center Technology-Stack"
                >
                  Technology Stack We Use
                </h2>
              </div>
            </div>
            <div className="row tech p-5 pt-0 mt-5 mb-5">
              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/physics.webp"
                  className="w-100 h-40"
                  alt="React"
                />
                <h5>React</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5>Python</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mysql.webp"
                  alt="MySQL"
                  className="w-100 h-40"
                />
                <h5>MySQL</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/java-script.webp"
                  alt="Java Srcipt"
                  className="w-100 h-40"
                />
                <h5>Java Srcipt</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/SQL-Lite.webp"
                  alt="SQL Lite"
                  className="w-100 h-40"
                />
                <h5>SQL Lite</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/html.webp"
                  alt="HTML"
                  className="w-100 h-40"
                />
                <h5>HTML</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/android.webp"
                  alt="Android"
                  className="w-100 h-40"
                />
                <h5>Android</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mongo-db-icon.webp"
                  alt="Mongo DB"
                  className="w-100 h-40"
                />
                <h5>Mongo DB</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/app-store.webp"
                  alt="App Store"
                  className="w-100 h-40"
                />
                <h5>App Store</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/atom.webp"
                  alt="Atom"
                  className="w-100 h-40"
                />
                <h5>Atom</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/laravel.webp"
                  alt="Laravel"
                  className="w-100 h-40"
                />
                <h5>Laravel</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5>Python</h5>
              </div>
            </div>
          </div>
        </section>
        <section className="why-MetaBlock-hyp">
          <div className="container">
            <div className="text-center why_hyp_content mb-4">
              <h2 className="hyp_common_title">
                Why MetaBlock for Ecommerce Website Development?
              </h2>
            </div>
            <div className="row mar-top-30">
              <div className="col-md-6 col-sm-6">
                <div className="list_item">
                  <ul className="list-unstyled">
                    <li>
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Customizable Ecommerce Website Solutions"
                        className="hyp-tic-sprite"
                      />
                      Customizable Ecommerce Website Solutions.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Professionals backed by 3+ years of industry experience"
                        className="hyp-tic-sprite"
                      />
                      Professionals backed by 3+ years of industry experience.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Direct collaboration with the technical team"
                        className="hyp-tic-sprite"
                      />
                      Direct collaboration with the technical team.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Precise quality assurance process"
                        className="hyp-tic-sprite"
                      />
                      Precise quality assurance process
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 ">
                <div className="list_item">
                  <ul className="list-unstyled">
                    <li>
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Websites built on leading-edge software packages"
                        className="hyp-tic-sprite"
                      />
                      Websites built on leading-edge software packages.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Incorporation of authentic API tools"
                        className="hyp-tic-sprite"
                      />
                      Incorporation of authentic API tools.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="Dedicated team for projects"
                        className="hyp-tic-sprite"
                      />
                      Dedicated team for projects.
                    </li>
                    <li className="mt-4">
                      <img
                        src="./SGimage/arrow_static.webp"
                        alt="On-time delivery at an affordable cost"
                        className="hyp-tic-sprite"
                      />
                      On-time delivery at an affordable cost.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
        <OurProjects
            projectData={projectData}
            heading="Browse our impactful portfolio showcase"
            description=" Our services encompasses web development,app development,game development,software development and blockchain development."
            filterKey="ecommerce" // Display only software projects
          />
          <SimilarFile />
        </div>
        <FAQSection2 category="ecommerce"/>
      </div>
    </>
  );
};

export default Ecommerce;
