import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import LibrarySwiperSlider from "./SwiperSlider/LibrarySwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";

import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const LibraryManagmentSystem = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Library Managment System Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Library Management Systems efficiently organize and track
                    library resources, including books, periodicals, and
                    multimedia materials. With features like cataloging,
                    circulation, and patron management, they streamline library
                    operations, enhance accessibility, and improve user
                    experience, ensuring libraries operate smoothly and
                    effectively in the digital age
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in library management system software, our focus
                is on delivering comprehensive solutions to efficiently organize
                and manage library resources. Our software is meticulously
                designed to streamline tasks such as cataloging, circulation,
                and patron management. With our expertise, libraries can easily
                track books, manage memberships, and facilitate lending
                processes. Our solution also offers features like automated
                reminders and fine tracking to ensure smooth operations. Whether
                it's a small community library or a large academic institution,
                our library management system software is tailored to meet the
                unique needs of each client, helping them enhance user
                experience, improve accessibility, and maximize the utilization
                of library resources.
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/libarydeva.webp" alt="libarydeva" />
            </div>
          </div>
        </section>

        <LibrarySwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
      <CardSlider/>
    </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
      <Phonecardslider />
    </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default LibraryManagmentSystem;
