import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";



import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";
import CabSwiperSlider from "./ApplicationSwiperSlider/CabSwiperSlider";
import SeoHelmet from "../../../Helmet";
import TechnologyStack from "../../../components/TechnologyStack";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const CabBookingApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="On-Demand A Best Taxi Booking App Development Company"
          description="Metablock technologies is the best taxi booking app development company, which provides services at a low-cost and effective price. Develop your own taxi booking app like Uber, Ola For Android,iOS, and hybrid platforms.
"
keywords="Taxi Booking App Development Company"
        />

        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      The Best Taxi Booking app development company- Make your
                      own Cab booking app
                    </h1>
                    <p className="heading-tagline">
                      Metablock technologies is the best taxi booking app
                      development company in jaipur creates easy-to-use apps for
                      booking rides, tracking drivers, and making secure
                      payments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Experts Of Cab Booking App
                </h2>
                <p style={{ fontSize: "20px" }}>
                  Metablock Technologies specialize in cab booking apps
                  development that are easy to use and offer real-time tracking
                  for a smooth ride. Our focus is on safety and reliability,
                  with strong security measures and trusted drivers. Whether
                  you're commuting, heading to the airport, or exploring a new
                  place, our app makes your trip comfortable and stress-free.
                  With multiple payment options and helpful customer support,
                  we’re here to ensure your journey is tension free.Try our cab
                  booking app today!
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
              </div>
            </div>
          </section>
          <CabSwiperSlider />
          
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>
          <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />
          <section>
            <SimilarFile />
          </section>
        </div>
        <FAQSection2 category="cabapp"/>
      </div>
    </>
  );
};

export default CabBookingApps;
