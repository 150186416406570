import React from 'react'

const TechnologyStack = () => {
  return (
    <div>
      
          {/*============= Technology Stack We Use========= */}

          <section className="section-heading mid_section-1">
            <div className="container">
              <div className="row">
                <div className="col-md-12 grident-color-sub-title">
                  <h2
                    style={{ color: "#D166AF ", fontSize: "40px" }}
                    className="text-center Technology-Stack"
                  >
                    Technology Stack We Use
                  </h2>
                </div>
              </div>
              <div className="row tech p-5 pt-0 mt-5 mb-5">
                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/physics.webp"
                    className="w-100 h-40"
                    alt="React"
                  />
                  <h5>React</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/python.webp"
                    alt="Python"
                    className="w-100 h-40"
                  />
                  <h5>Python</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/mysql.webp"
                    alt="MySQL"
                    className="w-100 h-40"
                  />
                  <h5>MySQL</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/java-script.webp"
                    alt="Java Srcipt"
                    className="w-100 h-40"
                  />
                  <h5>Java Srcipt</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/SQL-Lite.webp"
                    alt="SQL Lite"
                    className="w-100 h-40"
                  />
                  <h5>SQL Lite</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/html.webp"
                    alt="html"
                    className="w-100 h-40"
                  />
                  <h5>HTML</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/android.webp"
                    alt="Android"
                    className="w-100 h-40"
                  />
                  <h5>Android</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/mongo-db-icon.webp"
                    alt="Mongo DB"
                    className="w-100 h-40"
                  />
                  <h5>Mongo DB</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/app-store.webp"
                    alt="App Store"
                    className="w-100 h-40"
                  />
                  <h5>App Store</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/atom.webp"
                    alt="Atom"
                    className="w-100 h-40"
                  />
                  <h5>Atom</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/laravel.webp"
                    alt="Laravel"
                    className="w-100 h-40"
                  />
                  <h5>Laravel</h5>
                </div>

                <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                  <img
                    src="./images/python.webp"
                    alt="Python"
                    className="w-100 h-40"
                  />
                  <h5>Python</h5>
                </div>
              </div>
            </div>
          </section>
    </div>
  )
}

export default TechnologyStack
